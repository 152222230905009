import { IAppManagementSlice } from "../../../../contracts/slices/app/management/IAppManagementSlice";
import { LayoutType } from "../../../../contracts";
import { MessageType } from "../../../../enums";

/**
 * The Initial State of app Management slice.
 */
export const appManagementInitialState: IAppManagementSlice = {
	loader: {
		visible: false,
		text: "",
		size: ""
	},
	menu: {
		data: [],
		expanded: [],
		selectedId: "",
		selectedIds: []
	},
	layout: {
		layoutType: LayoutType.NORMAL_LIST,
		isHighZoomLevel: false
	},
	banner: {
		visible: false
	},
	message: {
		type: MessageType.info,
		text: "",
		isHtml: false,
		open: false
	},
	stickyNodes: [],
	userAccess: {
		nodeAdmin: false,
		eventAdmin: false,
		superAdmin: false
	},
};
