import React, { useCallback, useEffect, useRef } from "react";
import { NavDropdown } from "react-bootstrap";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { Link } from "react-router-dom";

import { IHeaderMenuItem } from "../../../contracts/appMenu";
import { ServiceHub } from "../../../service";
import { ScreenWidthSize, useScreenSize } from "../../../hooks";
import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../../redux/selectors/appManagement";

/**
 * Renders the a Menu component, including its children, recursively,
 * and memoized.
 *
 * @param props
 * @returns
 */
function MenuComponentByType(props: IHeaderMenuItem): React.ReactElement {
	const filterSvc = useRef(ServiceHub.filterAPI.start());
	const appMenuSvc = useRef(ServiceHub.appMenuAPI.start());
	const isMobile = useScreenSize(ScreenWidthSize.Mobile);
	const NavMenuRef = useRef(null);

	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const mapItems = useCallback((itemsToMap: IHeaderMenuItem[]) => {
		return itemsToMap.map((subItem, subIndex) => <MenuComponentByType {...subItem} key={`${subIndex}`} />);
	}, []);

	const handleMenuItemClick = () => {
		ServiceHub.filterAPI.start().resetTopicsFilters();
		filterSvc.current.resetUserCustomDashboard();
		ServiceHub.filterAPI.start().setSelectedDashBoardButton("Dashboards");
		ServiceHub.filterAPI.start().setDashboardNavigation(undefined);
	};

	// In case handling a menu divider
	if (props.Divider) {
		return <NavDropdown.Divider />;
	}

	const handleClickOutsideMenu = useCallback((event) => {
		if (NavMenuRef.current && !NavMenuRef.current.contains(event.target)) {
			appMenuSvc.current.resetAllMenu();
			appMenuSvc.current.resetSubMenus();
		}
	}, []);

	const handleCloseMenu = useCallback((event) => {
		if (event.key === "Escape") {
			appMenuSvc.current.resetAllMenu();
			appMenuSvc.current.resetSubMenus();
		}
	}, []);

	/**
	 * handling tab navigation on child node items
	 * @param event 
	 */
	const handleEnterChild = ((event) => {
		if (event.key === "Enter") {
			// event.preventDefault();
			if (event.target) {
				event.target.nextSibling.classList.toggle("show");
			}
			const allChildNodes = document.querySelectorAll('.dropdown-submenu-container .dropdown-menu');
			if (allChildNodes && allChildNodes.length > 0) {
				const subTreeParent = event.target.parentNode.parentNode;
				allChildNodes.forEach(node => {
					if (node && node !== event.target.nextSibling && node.classList.contains('show')) {
						node.classList.remove('show');
					}
				});
				subTreeParent.classList.add('show')
			}
		}
	});

	/**
	 * handling enter main items to reset opened submenus
	 * @param event 
	 */
	const handleEnterMain = ((event) => {
		if (event.key === "Enter") {
			const allChildNodes = document.querySelectorAll('.dropdown-submenu-container .dropdown-menu');
			allChildNodes.forEach(node => {
				node.classList.remove('show');
			});
		}
	});


	useEffect(() => {

		document.addEventListener("mousedown", handleClickOutsideMenu);
		document.addEventListener("keydown", handleCloseMenu);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideMenu);
			document.removeEventListener("keydown", handleCloseMenu);
		};
	}, [handleClickOutsideMenu]);

	useEffect(() => {
		const parentNodes = document.querySelectorAll('.dropdown-submenu-container a');
		const mainNodes = document.querySelectorAll('.nav-item > a');
		if (parentNodes && parentNodes.length > 0) {
			parentNodes.forEach(el => {
				el.setAttribute('tabIndex', "0");
				el.addEventListener("keydown", handleEnterChild);
			});
		}
		if (mainNodes && mainNodes.length > 0) {
			mainNodes.forEach(mainItem => {
				mainItem.addEventListener("keydown", handleEnterMain);
			});
		}
	
		return () => {
			if (parentNodes && parentNodes.length > 0) {
				parentNodes.forEach(el => {
					el.removeEventListener("keydown", handleEnterChild);
				});
			}
			if (mainNodes && mainNodes.length > 0) {
				mainNodes.forEach(mainItem => {
					mainItem.removeEventListener("keydown", handleEnterMain);
				});
			}
		};
	}, []);

	// In case this is a root point of the menu
	if (props.Root) {
		return isMobile || isHighZoomLevel ? (
			<div className="menu-mobile">
				<NavDropdown key={`dropdown-${props.Key}`} title={props.Title} id={props.Id}>
					{mapItems(props.Items)}
				</NavDropdown>
			</div>
		) : (
			<NavDropdown key={`dropdown-${props.Key}`} title={props.Title} id={props.Id}>
				{
					// Recursive rendering of sub-menu items
					mapItems(props.Items)
				}
			</NavDropdown>
		);
	}

	// In this step, we have a common menu-item
	if (props.Route && props.Route !== "") {
		return (
			<NavDropdown.Item
				key={`item-${props.Key}`}
				to={props.Route}
				as={Link}
				id={props.Id}
				onClick={handleMenuItemClick}
			>
				{props.Title}
			</NavDropdown.Item>
		);
	}

	// If no route was found, this menu is going to render as a dropdown submenu
	return (
		<DropdownSubmenu key={`submenu-${props.Key}`} title={props.Title} id={props.Id}>
			{
				// Recursive rendering of sub-menu items
				mapItems(props.Items)
			}
		</DropdownSubmenu>
	);
}

export { MenuComponentByType };

