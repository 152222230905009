import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useSuperAdminAccessControl } from "../../../hooks/auth/useSuperAdminAccessControl";
import { useAuthUser } from "../../../hooks";
import { useGlobalBannerForm } from "../../../hooks/banner/useBannerForm";
import { ApplicationRoutePaths } from "../../router";
import { UnauthorizedPage } from "../../common/page";
import { FormActions, FormContainer, FormField } from "../../common/form";
import { TextInput } from "../../common/text";
import { FormShimmer } from "../../common/shimmer";
import { useSelector } from "react-redux";
import { globalBannerFormSelectors } from "../../../redux/selectors/globalBannerForm";
import { IconButton } from "../../common/button";
import { ServiceHub } from "../../../service";
import { CoverImageType } from "../../../enums";
import { Toggle } from "@fluentui/react";

/**
 * Contract for the input props of the NodeForm.
 */
export interface IFormFieldsGlobalBannerProps {
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
}

/**
 * Space creation form
 *
 * @returns React.FC
 */
export const FormFieldsGlobalBanner: React.FC<IFormFieldsGlobalBannerProps> = (props) => {
	const { globalBannerFormData, isNewGlobalBanner, canSubmit, bannerId } = useGlobalBannerForm();
	const { userPrincipalName } = useAuthUser();
	const params = useParams();
	const location = useLocation();
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const [isImageLoaded, setIsImageLoaded] = useState(false);
    const bannerImageFile = useSelector(globalBannerFormSelectors.getBannerImageFile);
    const globalBannerImageRef = useRef<HTMLInputElement>(null);
    const globalBannerFormService = useRef(ServiceHub.globalBannerForm.start());
	const globalBannerAPIService = useRef(ServiceHub.globalBannerAdminAPI.start());
    const submitDisabled = useMemo(() => !canSubmit, [canSubmit]);
	const isSpaceFormLoading = false;

	/**
	 * Generic method to set specific space form property value
	 */
	const onSetStateProp = useCallback(
		function (prop: string, value: any) {
			globalBannerFormService.current.setData({
				...globalBannerFormData,
				[prop]: value
			});
		},
		[globalBannerFormService, globalBannerFormData]
	);

	/**
	 * Managing for input change value
	 */
	const onChange = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
		},
		[onSetStateProp]
	);

	/**
	 * Handle form discard button
	 * @param event
	 * @returns
	 */
	function onCancel(event: React.FormEvent<HTMLButtonElement>) {
		globalBannerFormService.current.resetBannerId();
		globalBannerFormService.current.resetData();
		globalBannerFormService.current.setBannerPanelOpen(false);
	}

	/**
	 * Used on input to select coverImage from HD
	 * @param event
	 */
	const onHandleBannerImage = (event) => {
		if (globalBannerImageRef.current) {
			globalBannerImageRef.current.click();
		}
	};

	/**
	 * Setting coverImage properties on prep for upload
	 */
	const onUploadBannerImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget;

			if (!files || files.length === 0) return;

			const fileUnit = files[0];
			const reader: FileReader = new FileReader();

			reader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
				globalBannerFormService.current.setData({
					...globalBannerFormData,
					bannerImageType: CoverImageType.Upload,
					bannerImageFile: reader.result.toString(),
					bannerImageMimeType: fileUnit.type,
					bannerImageSize: fileUnit.size,
					bannerImageName: fileUnit.name,
					bannerImageId: ""
				});
				globalBannerFormService.current.setBannerImageFile(reader.result.toString());
			};

			setIsImageLoaded(true);

			reader.readAsDataURL(fileUnit);
		},
		[globalBannerFormData, globalBannerFormService]
	);

	/**
	 * Render unauthorized component based on access
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	/**
	 * Utility to convert image string path to data image
	 * @param imagePath
	 */
	const getBannerImageData = (imagePath: string) => {
		ServiceHub.appFileAPI
			.start()
			.getCoverImageByURL(imagePath)
			.then((result) => {
				const imageData = result ? URL.createObjectURL(result) : null;
				globalBannerFormService.current.setBannerImageFile(imageData);
				setIsImageLoaded(true);
			});
	};

	useEffect(() => {
		globalBannerFormService.current.setData({
			...globalBannerFormData,
			authorUpn: userPrincipalName
		});

		if (location.pathname === ApplicationRoutePaths.spaceCreate()) {
			globalBannerFormService.current.resetData();
		} else {
			if (bannerId) {
				globalBannerAPIService.current
					.getBannerImage(bannerId)
					.then((result: any) => {
						if (result instanceof Error) {
							ServiceHub.message.error(result.message);
							throw result;
						}
						getBannerImageData(result.BannerImageName);
						globalBannerFormService.current.setData({
							...globalBannerFormData,
							id: result.Id,
							authorUpn: userPrincipalName,
							title: result.Title,
							link: result.Link,
							isCurrentBanner: result.IsCurrentBanner ? result.IsCurrentBanner : false,
							bannerImageName: result.BannerImageName
						});
					})
					.finally(() => {
                        // set loadings 
					});
			}
		}

		window.scrollTo(0, 0);
	}, [params.id, location]);

	if (isSuperAdmin)
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-12 col-xl-12" tabIndex={0} aria-label="Form Page">
					{isSpaceFormLoading ? (
						<FormShimmer />
					) : (
						<FormContainer
							onSubmit={props?.onSubmit ?? undefined}
							hideShadow
							//title={isNewGlobalBanner ? 'Create Banner' : "Edit Banner"}
						>
							<FormField id="Title" label="Title" required>
								<TextInput
									id="Title"
									placeholder="Enter a Global Banner Title here"
									onChange={onChange("title")}
									value={globalBannerFormData.title}
									required
									maxLength={200}
								/>
							</FormField>
							<FormField id="Link" label="Link" required>
								<TextInput
									id="Link"
									placeholder="Add Banner Link"
									onChange={onChange("link")}
									value={globalBannerFormData.link}
									required
									maxLength={200}
								/>
							</FormField>

							<FormField id="Banner Image" label="Banner Image">
								<IconButton iconName="ImageSearch" text="Select Image" onClick={onHandleBannerImage} />
								<input
									className="visibility-hidden"
									ref={globalBannerImageRef}
									id="globalBannerImage"
									type="file"
									onChange={onUploadBannerImage}
								/>
							</FormField>
							{bannerImageFile && isImageLoaded ? (
								<div className="global-banner-manager-image-display">
									<img src={bannerImageFile} alt="Global Banner Image" width="200" height="100" />
								</div>
							) : null}
								<FormField id={"isCurrentBanner"}>
								<Toggle
									id="isCurrentBanner"
									label="Current Banner"
									defaultChecked
									onText="Yes"
									offText="No"
									checked={globalBannerFormData.isCurrentBanner}
									onChange={(event: React.MouseEvent<HTMLElement>, checked: boolean) => {
										onSetStateProp("isCurrentBanner", checked);
									}}
								/>
							</FormField>

							<FormActions
								actions={[
									{
										type: "submit",
										text: globalBannerFormData ? "Save" : "Update",
										disabled: submitDisabled
									},
									{
										type: "button",
										variation: "secondary",
										className: "cancel",
										text: "Cancel",
										onClick: onCancel
									}
								]}
							/>
						</FormContainer>
					)}
				</div>
			</div>
		);

	if (
		(!isSuperAdmin) ||
		(!isSuperAdmin && isNewGlobalBanner && requested && !requesting)
	) {
		return renderUnauthorized();
	}
};
