import { IGlobalBannerFormData, IGlobalBannerItemsResponse } from "../../contracts";

/**
 * Global Banner Form data access selectors.
 */
export const globalBannerFormSelectors = {
	getData: (state): IGlobalBannerFormData => {
		return state.globalBannerForm.data;
	},
    getBannerImageFile: (state): string => {
		return state.globalBannerForm.bannerImageFile;
	},
	getBannersList: (state): IGlobalBannerItemsResponse[] => {
		return state.globalBannerForm.bannersList;
	},
	getBannerPanelOpen: (state): boolean => {
		return state.globalBannerForm.bannerPanelOpen;
	},
	getBannerId: (state): string => {
		return state.globalBannerForm.bannerId;
	},
};
