import React, { useEffect, useRef, useMemo, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { ScreenWidthSize, useScreenSize, useSpaceSlice } from "../../hooks";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { ButtonDropdownActions } from "../common/button";
import { Dropdown, TreeViewDropdown } from "../common/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import {
	resetSearchTitle,
	setSearchTitle,
	setSearchTopics,
	setSpaceSelectedValue,
	setToggleExpanderFilter
} from "../../redux/reducers/filter";
import { useFilterSlice } from "../../hooks/filter/useFilterSlice";
import { DirectionalHint, IconButton, ITooltipProps, TooltipHost } from "@fluentui/react";
import { filterSelectors } from "../../redux/selectors/filter";
import Constants from "../../constants/Constants";
import { ServiceHub } from "../../service";
import { ApplicationRoutePaths } from "../router";
import { Icon } from "semantic-ui-react";

interface IFilterProps {}

/**
 * Dictionary of strings consumed within the module
 */
const strings = {
	title: {
		button: {
			expand: "Expand Filters",
			collapse: "Collapse Filters",
			clear: "Clear Filters"
		},
		input: {
			searchText: "Input: Type to start narrowing your search"
		},
		select: {
			topics: "Select: Narrow to Topics from the dropdown list",
			spaces: "Select: A Space to Scope your search"
		}
	},
	placeholder: {
		//searchText: "Filter by article's or question's title... ",
		searchText: "Search by author:, title:, body: and free text",
		topics: "Filter by topics or tags...",
		spaces: "Select and Navigate to Space..."
	},
	params: {
		topics: "topics"
	}
};

/**
 * Renders a top toolbar with Filtering elements.
 * For using on top of pages.
 *
 * @param props Supported properties are IFilterProps.
 * @returns React.FunctionComponent<IFilterProps>
 */
export const Filter: React.FunctionComponent<IFilterProps> = (props) => {
	let [searchParams, setSearchParams] = useSearchParams();
	const searchTimeout = useRef<NodeJS.Timeout | null>(null);
	const params = useParams();
	const filterData = useFilterSlice();
	const hasPageBeenRendered = useRef(false);
	const previousNodeId = useRef(null);
	const previousSearchTitle = useRef("");
	const [searchTitle, setSearchTitleValue] = React.useState("");
	const [prevTopics, setPrevTopics] = React.useState("");
	const [nodesAllTopicsOptions, setNodesAllTopicsOptions] = React.useState([]);
	const spaceData = useSpaceSlice();
	const spaceTree: ITreeItem[] = spaceData.treeView;
	const selectedSpaceId: null | number | string = spaceData.selectedId;
	const loadingSpaces: boolean = spaceData.loading;
	const bannerVisible = useSelector(appManagementSelectors.getBannerVisible);
	const searchTopics = filterData.searchTopics;
	const dashboardNavigation = filterData.dashboardNavigation;
	const userCustomDashboard = filterData.userCustomDashboard;
	const isFilterExpanded = filterData.isFilterExpanded;
	const topicsResetKey = filterData.topicsResetKey;
	const isDashboardLoading = filterData.isDashboardLoading;
	const searchTopicsItems = useMemo(() => (!searchTopics ? [] : searchTopics.split(",")), [searchTopics]);
	const nodeId = useMemo(() => Number(params.id), [params.id]);
	const spaceSelectedValue = useSelector(filterSelectors.getSpaceSelectedValue);
	const dispatch = useDispatch();
	const location = useLocation();
	const isFilterVisible = useSelector(filterSelectors.getIsFilterVisible);
	const inputSearch = useRef(null);
	const filterAPI = useRef(ServiceHub.filterAPI.start());
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const isMobileDevice = useScreenSize(ScreenWidthSize.Mobile);

	/**
	 * Effect to address an accessibility issue on the Dropdown SemanticUI component
	 */
	useLayoutEffect(() => {
		const inputElement = document.querySelector("#search-nav-topic-dropdown input");
		if (inputElement) {
			inputElement.setAttribute("title", strings.title.select.topics);
		}
	}, [topicsResetKey]);

	useEffect(() => {
		if (!isMobileDevice) {
			window.addEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			return () => {
				window.removeEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			};
		}
	}, []);

	/**
	 * Monitors the change of nodeId, in essence for updating the
	 * topics dropdown data.
	 */
	useEffect(() => {
		async function loadTopics() {
			if (!hasPageBeenRendered.current || nodeId != previousNodeId.current) {
				hasPageBeenRendered.current = true;
				previousNodeId.current = nodeId;
				getNodesAllTopics();
			}
		}

		// Pre-loads the topics list
		loadTopics();
	}, [nodeId, previousNodeId]);

	/**
	 * Monitors the Search title text,
	 * which will dispatch and execute the search using debounce technique.
	 */
	useEffect(() => {
		async function delayedSearch() {
			/* calling searchNodes method it there is a change on the searchTitle keyword
			 *  or checkNewTopic() returns true if new tag/topic was added or removed
			 *  from the tags selector.
			 *  Also adding a little delay in milliseconds to process the query.
			 */
			if (!isDashboardLoading && filterData.searchTitle !== previousSearchTitle.current) {
				// Forcing a clean-up before a new timeout, since
				// It generates debounce effect
				clearTimeout(searchTimeout.current);

				previousSearchTitle.current = filterData.searchTitle;

				searchTimeout.current = setTimeout(() => {
					searchNodes();
				}, 600);
			}
		}

		// Pre-processes the query, if specified
		delayedSearch();
	}, [isDashboardLoading, filterData.searchTitle, previousSearchTitle.current, searchTimeout.current]);

	/**
	 * Monitors the topics selection,
	 * which will dispatch and execute the search of new entries.
	 */
	useEffect(() => {
		async function updateParams() {
			/* calling searchNodes method it there is a change on the searchTitle keyword
			 *  or checkNewTopic() returns true if new tag/topic was added or removed
			 *  from the tags selector.
			 *  Also adding a little delay in milliseconds to process the query.
			 */
			if (!isDashboardLoading && checkNewTopic()) {
				if (location.pathname.includes(ApplicationRoutePaths.topicsDashboard())) {
					if (!searchTopics) {
						searchParams.delete(strings.params.topics);
						setSearchParams(searchParams);
					} else {
						searchParams.set(strings.params.topics, searchTopics);
						setSearchParams(searchParams);
					}
				} else {
					searchNodes();
				}
			}
			setPrevTopics(searchTopics);
		}

		// Updates query params, if specified
		updateParams();
	}, [isDashboardLoading, searchTopics]);

	/**
	 * The useEffect below keeps sync between the ID in the URL,
	 * and the ID selected from the SpaceID Dropdown
	 */
	useEffect(() => {
		if (params?.id && params?.id !== selectedSpaceId?.toString()) {
			spaceData.service.setSelectedId(params?.id ?? null);
		}
	}, [params?.id, selectedSpaceId, spaceData]);

	/**
	 * Monitors the filter visibility,
	 * across when the Strike banner component is available.
	 */
	useEffect(() => {
		if (isFilterVisible && bannerVisible) {
			const handleScroll = () => {
				const filterBar = document.getElementById("filter-bar");
				if (window.pageYOffset > Constants.filterScroll.offSetHeight) {
					filterBar?.classList.add("fixed");
				} else {
					filterBar?.classList.remove("fixed");
				}
			};

			window.addEventListener("scroll", handleScroll);
			return () => window.removeEventListener("scroll", handleScroll);
		}
	}, []);

	/**
	 * Setting routes/dashboards where Filter module its enabled
	 */
	useEffect(() => {
		if (inputSearch.current) {
			setSearchTitleValue("");
			dispatch(setSearchTitle(""));
		}
	}, [location.pathname]);

	const getNodesAllTopics = async () => {
		let nodeTopics = [];
		let res = await NodeAPIServiceInstance.getNodesAllTopics();
		if (res) {
			res.map((item) => {
				nodeTopics.push({ key: item, text: item, value: item });
			});
			setNodesAllTopicsOptions(nodeTopics);
		}
	};

	const checkNewTopic = () => {
		return searchTopics.length != prevTopics.length;
	};

	const searchNodes = () => {
		const pageIndexValue = 1;
		filterData.onSearch(
			pageIndexValue,
			filterData.searchTitle,
			searchTopics,
			dashboardNavigation,
			userCustomDashboard
		);
	};

	const handleChange = async (event) => {
		let value = event.target.value;
		setSearchTitleValue(value);
		dispatch(setSearchTitle(value));
	};

	const handleTopicChange = (event, data) => {
		const searchTopics = data.value as [];
		dispatch(setSearchTopics(searchTopics.join(",")));
	};

	const handleClearSpace = () => {
		spaceData.service.setSelectedId(null);
	};

	const handleFilterExpand = (event) => {
		event.preventDefault();
		dispatch(setToggleExpanderFilter());
	};

	const handleSelectedItem = (item) => {
		const selectedItemValue = { id: item.id, text: item.title };
		dispatch(setSpaceSelectedValue(selectedItemValue));
		spaceData.service.setSelectedId(selectedItemValue.id);
		filterAPI.current.resetUserCustomDashboard();
		filterAPI.current.setSelectedDashBoardButton(Constants.buttonDashboardsTexts.Dashboards);
		filterAPI.current.resetOnlyTopicsValues();
		filterAPI.current.setDashboardNavigation(undefined);
	};

	const handleClearButton = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.root());
		ServiceHub.appManagementAPI.resetDashboardFilters();
		filterAPI.current.setDashboardNavigation(undefined);
		setSearchTitleValue("");
		dispatch(resetSearchTitle());
	};

	const tooltipProps: ITooltipProps = {
		calloutProps: { hidden: false },
		onRenderContent: () => (
			<>
				<div className="search-keyword-tooltip-container">
					<ul className="search-keyword-tooltip-list">
						<li>
							<strong>author:</strong> Created by author
						</li>
						<li>
							<strong>title:</strong> Search Nodes by the title
						</li>
						<li>
							<strong>body:</strong> Search in the body of Node
						</li>
						<li>
							<strong>Without above prefix:</strong> Free text search
						</li>
					</ul>
					<p className="search-keyword-tooltip-footer">
						<strong>Example</strong> - author:Author email or name, body:Article/Question body
					</p>
				</div>
			</>
		)
	};

	return (
		<>
			<div
				id="filter-bar"
				className={`subheader-container ${bannerVisible ? "" : isHighZoomLevel ? "" : "fixed"}`}
			>
				<div className="container">
					<div className="justify-content-center row">
						<div className="col-lg-12">
							<div className="col-lg-12">
								<div className="node-list-widgets mb-2">
									<form action="#" className="">
										<div className="filter-container">
											<div className="filter-fields">
												<div className="filter-field-item">
													<div className={`filler-job-form search-box title-filter form-field ${isHighZoomLevel ? "filter-search-high-zoom" : ""}`}>
															<TooltipHost
																tooltipProps={tooltipProps}
																id="tooltip"
																directionalHint={DirectionalHint.bottomCenter}
															>
																<Icon
																	name="info circle"
																	color="blue"
																	size="small"
																	className="search-info-tooltip-icon"
																/>
															</TooltipHost>
														<input
															id="filter-search-text"
															value={searchTitle}
															placeholder={strings.placeholder.searchText}
															type="search"
															className="form-control-type full-width"
															onChange={handleChange}
															tabIndex={0}
															autoComplete="off"
															ref={inputSearch}
															title={strings.title.input.searchText}
															alt={strings.title.input.searchText}
														/>
													</div>
												</div>
												<div
													className={`filter-field-item ${
														isFilterExpanded ? "" : "condensed"
													}`}
												>
													<div className="filler-job-form">
														<Dropdown
															id="search-nav-topic-dropdown"
															key={topicsResetKey}
															placeholder={strings.placeholder.topics}
															width={16}
															fluid
															search
															selection
															multiSelect
															className="search-box"
															options={nodesAllTopicsOptions}
															onChange={handleTopicChange}
															title={strings.title.select.topics}
															ariaLabel={strings.title.select.topics}
															selectedValue={searchTopicsItems}
															closeOnBlur
															closeOnEscape
															skipTabIndexing
														/>
													</div>
												</div>
												<div
													className={`filter-field-item ${
														isFilterExpanded ? "" : "condensed"
													}`}
												>
													<div className="form-field">
														<TreeViewDropdown
															id={"spaces"}
															options={spaceTree}
															open={spaceData.open}
															setOpen={spaceData.setOpen}
															onClear={handleClearSpace}
															onChange={handleSelectedItem}
															placeholder={strings.placeholder.spaces}
															onItemToggled={(item, isExpanded) => {
																spaceData.service.setExpandedSpaceById(
																	parseInt(item.id),
																	isExpanded
																);
															}}
															selectedItem={spaceSelectedValue}
															disabled={loadingSpaces}
															search
															title={strings.title.select.spaces}
															alt={strings.title.select.spaces}
															navigable
														/>
													</div>
												</div>
											</div>
											<div className={`filter-actions ${isFilterExpanded ? "" : "condensed"}`}>
												<ButtonDropdownActions />
											</div>
											<div
												className={`filter-actions-clear-button ${
													isFilterExpanded ? "expanded" : ""
												}`}
											>
												<IconButton
													iconProps={{ iconName: "Broom" }}
													className="filter-actions-clear-icon-button"
													onClick={handleClearButton}
													ariaLabel={"Clear Filters"}
													title={strings.title.button.clear}
													alt={strings.title.button.clear}
												/>
											</div>
										</div>
										<div
											className={`filter-container-expander ${
												isFilterExpanded ? "expanded" : ""
											}`}
										>
											<IconButton
												iconProps={{ iconName: "PageListFilter" }}
												className="filter-container-expander-button"
												onClick={handleFilterExpand}
												title={
													isFilterExpanded
														? strings.title.button.collapse
														: strings.title.button.expand
												}
												alt={
													isFilterExpanded
														? strings.title.button.collapse
														: strings.title.button.expand
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
