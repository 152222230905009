import { useSelector } from "react-redux";
import { ITopicsExpertsResponse } from "../../contracts/topic/ITopicsExpertResponse";
import { topicsExpertsSelectors } from "../../redux/selectors/topicsExperts";

/**
 * Contract for the Global Banner Form hook.
 */
interface ITopicsExpertsData {
    topicsExpertsData: ITopicsExpertsResponse[];
    topThreeExperts: ITopicsExpertsResponse[];
    additionalExperts: ITopicsExpertsResponse[];
}

/**
 * Hook to handle and processing Global Banner Form data.
 * @returns ISpaceFormHook
 */
export const useTopicsExpertsData = (): ITopicsExpertsData => {
    const topicsExpertsData = useSelector(topicsExpertsSelectors.getTopicsExperts);
    const topThreeExperts = useSelector(topicsExpertsSelectors.getFirstThreeExperts);
    const additionalExperts = useSelector(topicsExpertsSelectors.getAdditionalExperts);


	return {
        topicsExpertsData,
        topThreeExperts,
        additionalExperts
	} as ITopicsExpertsData;
};