import React from "react";
import { Navigate } from "react-router";

// Necessary interface imports
import { IApplicationRouteTable } from "../../contracts";

// Page Component imports
import NodesDashboard from "../dashboard/dashboard";
import FileDownloadPage from "../download/fileDownload.page";
import AppLayout from "../layout/AppLayout";
import TopicDashboard from "../topicDashboard/topics.dashboard";

// Article Route Maps
import { ApplicationRoutePaths, RoutePathStrings } from "./maps/applicationRoutePaths";
import { ArticleRoutes } from "./maps/articleRoutes";
import { QuestionRoutes } from "./maps/questionRoutes";
import { SpaceRoutes } from "./maps/spaceRoutes";
import { EventRoutes } from "./maps/eventRoutes";
import RouteErrorBoundary from "./RouteErrorBoundary";

/**
 * Reusable ApplicationRoute table.
 * V2 made.
 */
export const ApplicationRoutes: IApplicationRouteTable = {
	// Root Application AppLayout based-pages
	Root: {
		path: RoutePathStrings.root,
		Component: AppLayout,
		errorElement: <RouteErrorBoundary />,
		children: [
			// Map Article Routes
			...ArticleRoutes,
			// Map Question Routes
			...QuestionRoutes,
			// Map Space Routes
			...SpaceRoutes,
			// Map Events Routes
			...EventRoutes,
			// Topic Dashboard
			{
				path: RoutePathStrings.topicsDashboard,
				Component: TopicDashboard
			},
			// View Topic
			{
				path: RoutePathStrings.topics,
				Component: NodesDashboard
			},
			// Dashboard:
			{
				path: RoutePathStrings.dashboard,
				Component: NodesDashboard
			},
			// Default:
			{
				path: RoutePathStrings.root,
				index: true,
				Component: NodesDashboard
			}
		]
	},
	// Independent module: Download Storage Attachment
	AttachmentDownload: {
		path: RoutePathStrings.attachmentDownload,
		Component: FileDownloadPage
	},
	Any: {
		path: RoutePathStrings.any,
		Component: () => <Navigate to={ApplicationRoutePaths.root()} replace />
	}
};
