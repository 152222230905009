import { setNodeAuthorModel } from "../../contracts/models";
import { NodePreprocessingAPI } from "../base/NodePreprocessingAPI";

/**
 * Super Administrative tooling services class.
 */
export class SuperAdminAPI extends NodePreprocessingAPI {
	constructor() {
		super("admin");
	}

	/**
	 * Executes a check on whether the current user
	 * logged in can consume Super Admin services.
	 *
	 * @returns
	 */
	async reviewAccess(): Promise<boolean> {
		return await this.get("ReviewAccess")
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}


	/** 
	 * Update Article Author
	 */
	async updateArticleAuthor(setNodeAuthor: setNodeAuthorModel): Promise<boolean> {
		return await this.post("setNodeAuthor", setNodeAuthor)
			.then((result) => true)
			.catch(() => {
				return false;
			});
	}
}
