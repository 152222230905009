import React from 'react';
import { useRouteError } from 'react-router-dom';
import HeaderMenu from '../Header/header.menu';
import Footer from '../Footer/Footer';
import ErrorPage from '../ui/ErrorPage';

function RouteErrorBoundary() {
    // in case we want to use error 
    const error = useRouteError() as Error;
     return (
        <div className="d-flex flex-column">
            <HeaderMenu />
            <div className="container page-container">
                <div className="global-error-page">
                <ErrorPage
                        header={"Sorry, an unexpected error has occurred..."}
                        isHtml={true}
                        message={`
                        Please <a href="/"><strong> navigate to home </strong></a> and try again
                    `}
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default RouteErrorBoundary;