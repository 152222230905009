import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../../redux/selectors/appManagement";
import { AppMenuAPI } from "../../../service/app/AppMenuAPI";
import { MenuComponentByType } from "./menuComponentByType";

/**
 * WIP: Component for building the menu as a whole mechanism.
 *
 * @param props
 * @returns
 */
function MenuBuilder(props): React.ReactElement {
	const remoteMenuItems = useSelector(appManagementSelectors.getAppMenuData);
	const rootMenuItems = useMemo(() => (remoteMenuItems?.Items ? remoteMenuItems.Items : []), [remoteMenuItems]);
	const appManagementService = useRef(new AppMenuAPI());
	const hasEventAuthorization = useSelector(appManagementSelectors.getIsEventAdminUser);

	useEffect(() => {
		if (appManagementService.current) {
			appManagementService.current.getMenu();
		}
	}, []);

	return (
		<>
			{rootMenuItems.map((menuItem, index) => {
				if (menuItem?.Authorize && !hasEventAuthorization) return null;
				return <MenuComponentByType {...menuItem} key={`${index}`} root />;
			})}
		</>
	);
}

export { MenuBuilder };
