import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import {
	IEventUserToCheckin,
	UserAcceptAnswerModel,
	UserActivityModel,
	UserAuthoriablesModel
} from "../contracts/models";
import { NodeAPIServiceInstance } from "../service/NodeAPI";
import store from "../redux/store";
import { setAuthoriableId } from "../redux/reducers/auth";
import { EventAuthAccessModel, EventFormInfoModel } from "../contracts/models/request/userEventRequest";
import { setAllNodeComments, setNodeLikeByUsers, setNodeLikeCount } from "../redux/reducers/comments";
import { IEventRegistrationInfoRequestModel, IEventUserRegistrationRequest } from "../contracts/event/eventForm";
import html2pdf from 'html2pdf.js';

export const showToast = (message: string, type: string) => {
	if (type == "success") {
		toast.success(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined
		});
	}
	if (type == "error") {
		toast.error(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined
		});
	}
};

export function propertyOf<TObj>(name: keyof TObj) {
	return name;
}

// export const getFormattedDate = (date) => {
//     var year = date.getFullYear();

//     var month = (1 + date.getMonth()).toString();
//     month = month.length > 1 ? month : '0' + month;

//     var day = date.getDate().toString();
//     day = day.length > 1 ? day : '0' + day;

//     return month + '/' + day + '/' + year;
// }

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

/**
 * Utility to convert date to local time
 * @param inputDate
 * @returns
 */
export const utcToLocalDateTimeString = (inputDate: Date | string): string => {
	//return moment(inputDate).tz("America/Los_Angeles").format('MM/DD/YYYY h:mm a');
	//return moment(inputDate).local().format('MM/DD/YYYY h:mm a');
	return moment.utc(inputDate).local().format("MM/DD/YYYY h:mm A");
};

/**
 * Utility to convert date to local time without hours information
 * @param inputDate
 * @returns
 */
export const utcToLocalDateString = (inputDate: Date | string): string => {
	return moment.utc(inputDate).local().format("MM/DD/YYYY");
};

export const formatURLQueryString = (value: string): string => {
	if (value === value.toLowerCase()) {
		value = value.charAt(0).toUpperCase() + value.slice(1);
	}
	return value.replace(/([A-Z])/g, " $1").trim();
};

export const createSpaceInValue = (value: string): string => {
	return value.replace(/([A-Z])/g, " $1").trim();
};

/**
 * Validate url pattern and start with https
 * @param url 
 * @returns 
 */
export const validateUrl = (url: string): boolean => {
	const urlPattern = /^(https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?$/;
	return urlPattern.test(url);
  };

// export const utcToLocalDateTime = (inputDate: Date): Date => {
//     //return moment(inputDate).tz("America/Los_Angeles").format('MM/DD/YYYY h:mm a');
//     //return moment(inputDate).local().format('MM/DD/YYYY h:mm a');
//     return moment.utc(inputDate).local().format();
// }

export const geDateHoursDifference = () => {
	// var StartDateTime = moment(eventModelTemp.StartDateTime),
	// EndDateTime = moment(eventModelTemp.EndDateTime),
	// totalHours = EndDateTime.diff(StartDateTime, 'hours', true)
};

export const isValidUrl = (url): boolean => {
	try {
		new URL(url);
		return true;
	} catch (err) {
		return false;
	}
};

export const getDates = (startDate: Date, endDate: Date): any[] => {
	const dates = [];
	let currentDate = startDate;
	const addDays = function (days) {
		const date = new Date();
		date.setDate(date.getDate() + days);
		return date;
	};
	while (currentDate <= endDate) {
		dates.push(currentDate);
		currentDate = addDays.call(currentDate, 1);
	}
	return dates;
};

/**
 * Utility to render PDF fil using engine library accpting filenam params
 * @param nodeType 
 * @param nodeId 
 */
export function renderNodePdf(nodeType: string, nodeId: string) {
	const element = document.getElementById('export-to-pdf-template');

		if(element) {
			element.style.display = 'block';
			const options = {
				margin: 0,
				filename: 'Strike' + nodeType + '_' + nodeId + '.pdf',
				html2canvas: { scale : 2},
				jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
			};

			html2pdf()
			.set(options)
			.from(element)
			.save()
			.then(() => {
				element.style.display = 'none';
			})
			.catch((error) => {
				console.error('Error rendering PDF:', error);
			});
		}
}

export const mapNodeActions = (userAction: string): string => {
	switch (userAction) {
		case "accept":
			return "accepted";
		case "action":
		case "addNodeAlias":
		case "add-to-group":
		case "cancelVoteDown":
		case "cancelVoteUp":
		case "close":
			return "action";
		case "answer":
			return "answered";
		case "answer2comment":
			return "answeredtocomment";
		case "ask":
			return "asked";
		case "asktoanswer":
			return "asktoanswer";
		case "bonusrep":
			return "bonusrep";
		case "bulk":
			return "bulk";
		case "comment":
		case "comment2answer":
			return "commented";
		case "deactivate":
			return "deactivate";
		case "delete":
			return "deleted";
		case "delete-space":
			return "delete-space";
		case "edit-space":
			return "edit-space";
		case "edit-topic-desc":
			return "edit-topic-desc";
		case "edit-topic-name":
			return "edit-topic-name";
		case "expertanswer":
			return "expertanswer";
		case "favorite":
			return "favorite";
		case "flag":
			return "flag";
		case "follow":
			return "followed";
		case "ghostrevise":
			return "ghostrevise";
		case "giveRep":
			return "giveRep";
		case "lock":
			return "lock";
		case "MakeSiteStickyAction":
			return "MakeSiteStickyAction";
		case "MakeStickyAction":
			return "MakeStickyAction";
		case "moveToSpace":
			return "moveToSpace";
		case "newkbentry":
			return "newkbentry";
		case "newtopic":
			return "newtopic";
		case "nodeview":
			return "nodeview";
		case "organizational":
			return "organizational";
		case "outdated":
			return "outdated";
		case "publish":
			return "published";
		case "redirectQuestion":
			return "redirectQuestion";
		case "redirectQuestionTo":
			return "redirectQuestionTo";
		case "reject":
			return "reject";
		case "removeFromGroup":
		case "reopen":
		case "retag":
		case "revise":
		case "rollback":
			return "edited";
		case "sendToMod":
			return "sendToMod";
		case "smart-space":
			return "smart-space";
		case "space":
			return "space";
		case "suspend":
			return "suspend";
		case "switch-privacy":
			return "switch-privacy";
		case "topicIcon":
			return "topicIcon";
		case "topicUsed":
			return "topicUsed";
		case "unaccept":
			return "unaccept";
		case "undeactivate":
			return "undeactivate";
		case "undelete":
			return "undelete";
		case "undoMakeSiteSticky":
			return "undoMakeSiteSticky";
		case "undoMakeSticky":
			return "undoMakeSticky";
		case "unfavorite":
			return "unfavorite";
		case "unflagIndex":
			return "unflagIndex";
		case "unfollow":
			return "unfollow";
		case "unsuspend":
			return "unsuspend";
		case "unwikify":
			return "unwikify";
		case "useredit":
			return "useredit";
		case "userjoins":
			return "userjoins";
		case "userjoinsite":
			return "userjoinsite";
		case "votedown":
			return "unliked";
		case "voteup":
			return "liked";
		case "WikifyAction":
			return "Wikify";
		default:
			return userAction;
	}
};

/**
 * query Model to pass userActivity object
 */
export function getQueryUserActivityObject(
	actionType: string,
	ipAddress: string,
	nodeNumber: string,
	emailID: string
): UserActivityModel {
	return {
		actionType: actionType,
		ipAddress: ipAddress,
		nodeId: nodeNumber,
		emailID: emailID
	} as UserActivityModel;
}

/**
 * query Model to Accept Answer object
 */
export function getQueryUserAcceptAnswerObject(
	actionType: string,
	ipAddress: string,
	nodeNumber: string,
	emailID: string,
	rootNodeID: string,
	isAnswerAccepted: boolean
): UserAcceptAnswerModel {
	return {
		actionType: actionType,
		ipAddress: ipAddress,
		nodeId: nodeNumber,
		emailID: emailID,
		rootNodeID: rootNodeID,
		isAnswerAccepted: isAnswerAccepted
	} as UserAcceptAnswerModel;
}

/**
 * method to call api and log user activity workflow
 */
export const activityUser = async (userActivityModel: UserActivityModel) => {
	await NodeAPIServiceInstance.logUserActivity(userActivityModel);
};

/**
 * query Model to createAuthUser
 */
export function getQueryCreateAuthUser(id: string, name: string, email: string): UserAuthoriablesModel {
	return {
		id: id,
		name: name,
		email: email
	} as UserAuthoriablesModel;
}

/**
 * method to create Auth user
 * @returns id
 */
export const createAuthoriableUser = async (createAuthoriableUser: UserAuthoriablesModel) => {
	let res = await NodeAPIServiceInstance.createAuthoriablesUser(createAuthoriableUser);
	store.dispatch(setAuthoriableId(res));
};

/**
 * get likes and comments total number and refresh comments on tree nav
 * @returns response
 */
export const getCommentActivityData = async (itemId: string) => {
	let res = await NodeAPIServiceInstance.getById(Number(itemId));
	store.dispatch(setNodeLikeCount(res.LikeCount));
	store.dispatch(setNodeLikeByUsers(res.LikeByUsers));
	let comments = await NodeAPIServiceInstance.getNodeComments(Number(itemId));
	store.dispatch(setAllNodeComments(comments));
};

/**
 * query Model to pass User Event Registration
 */
export function getQueryEventRegistration(
	userName: string,
	userEmail: string,
	rsvp: string,
	typeOfSession?: string,
	typeOfRSVP?: string,
	isAzureEdge?: string,
	leaderName?: string
): EventFormInfoModel {
	return {
		userName: userName,
		userEmail: userEmail,
		rsvp: rsvp,
		typeOfSession: typeOfSession,
		typeOfRSVP: typeOfRSVP,
		isAzureEdge: isAzureEdge,
		leaderName: leaderName
	} as EventFormInfoModel;
}

/**
 * query Model Event Auth Access
 */
export function getQueryEventAuthAccess(userEmail: string, eventId: string): EventAuthAccessModel {
	return {
		userEmail: userEmail,
		eventId: eventId
	} as EventAuthAccessModel;
}

/**
 * New model shape for User Registation for new Event Management
 */
export function shapeUserEventRegistration(
	id: null | string,
	eventId: string,
	userEmail: string,
	userName: string,
	rsvp: string,
	typeOfRsvp: string,
	checkedIn?: boolean
): IEventUserRegistrationRequest {
	return {
		id: id,
		eventId: eventId,
		userEmail: userEmail,
		userName: userName,
		rsvp: rsvp,
		typeOfRsvp: typeOfRsvp,
		checkedIn: checkedIn
	} as IEventUserRegistrationRequest;
}

/**
 * query Model to check User Event Registration
 */
export function shapeEventRegistrationInfoRequest(eventId?: number): IEventRegistrationInfoRequestModel {
	return {
		eventId: eventId
	} as IEventRegistrationInfoRequestModel;
}

/**
 * query Model to checkin user in Event Checkin Dashboard
 */
export function getQueryUserToEventCheckin(id: number, email: string): IEventUserToCheckin {
	return {
		id: id,
		email: email
	} as IEventUserToCheckin;
}

/**
 * Encodes a raw string to a Base-64 protected one.
 * Can be enabled for URL format, which removes equals signs at the end.
 *
 * @param content The content to Base-64 encode
 * @returns The Base-64 encoded version of the string
 */
export function base64Encode(content: string): string {
	return btoa(unescape(encodeURIComponent(content)));
}

/**
 * Decodes a Base-64 string to a raw one.
 * Can be enabled for detecting URL format, which considers equals signs at the end.
 *
 * @param content The content to Base-64 decode
 * @returns The raw version of the input Base-64 string
 */
export function base64Decode(base64Content: string): string {
	return decodeURIComponent(escape(window.atob(base64Content)));
}
