import React, { useState } from "react";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { Link } from "react-router-dom";
import { IconButton } from "../common/button";
import { useEffect } from "react";
import { IGlobalBannerItem, ISpaceItem } from "../../contracts";
import { useRef } from "react";
import { ServiceHub } from "../../service";
import { ConfirmationMessage } from "../common/message";
import { UnauthorizedPage } from "../common/page";
import { ListShimmer } from "../common/shimmer/list/shimmer.list";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { CustomPanel } from "../common/panel/Panel";
import { GlobalBannerForm } from "./shared/global.banner.form";
import { useGlobalBannerForm } from "../../hooks/banner/useBannerForm";

export interface GlobalBannerList extends IGlobalBannerItem {
	name: string;
	BannerImageData: string;
}

const GlobalBannerManage: React.FC = () => {

	useEffect(() => {
		document.title = 'Manage Global Banner - Strike Community'
	}, []);

	const [globalBannerFormTitle, setGlobalBannerFormTitle] = useState('');
	const [bannersDataReady, setBannersDataReady] = useState(false);
	const globalBannerDataAPI = useRef(ServiceHub.globalBannerAdminAPI.start());
	const globalBannerFormService = useRef(ServiceHub.globalBannerForm.start());
	const [isOpenDeleteBannerConfirmation, setIsOpenDeleteBannerConfirmation] = useState<boolean>(false);
	const [bannerIdToDelete, setBannerIdToDelete] = useState(null);

	const {
		processedBannerItems, isBannerPanelOpen
	} = useGlobalBannerForm();
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
    const isManageBannerListLoaded = true;
    const isManageBannerListLoading = false;
    const isManageListLoading = false;
	
	/**
	 * Creates the structure for the Global Banner Dashboard List
	 */
	const columns: IColumn[] = [
		{
			key: "Title",
			name: "Title",
			fieldName: "Title",
			minWidth: 220,
			maxWidth: 220,
			isResizable: true,
            onRender: (item: GlobalBannerList) => (
				<div className="global-banner-manager-title">
				    <Link to={item.Link} className="global-banner-manager-link">{item.Title}</Link>
				</div>
			)
		},
        {
			key: "bannerImage",
			name: "Banner Image",
			fieldName: "bannerImage",
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
			onRender: (item: GlobalBannerList) => (
				<img src={item.BannerImageData} alt={`${item.Title} banner`} title={`${item.Title} banner`} width="150" height="100" />
			)
		},
        {
			key: "isCurrentBanner",
			name: "Current Banner?",
			fieldName: "isCurrentBanner",
			minWidth: 120,
			maxWidth: 120,
			onRender: (item: GlobalBannerList) => (
				<div className="global-banner-manager-current">
					<span className="global-banner-current-column">
						{item.IsCurrentBanner ? "Yes" : 'No'}
					</span>
				</div>
			)
		},
		{
			key: "actions",
			name: "",
			minWidth: 80,
			maxWidth: 90,
			onRender: (item: GlobalBannerList) => (
				<div className="global-banner-manager-actions-column">
					<IconButton iconName="Edit" ariaLabel="Edit" onClick={() => handleEdit(item.Id.toString())} />
					<IconButton
						iconName="Delete"
						ariaLabel="Delete"
						onClick={() => handleRemove(item.Id.toString())}
						disabled={item.IsCurrentBanner}
					/>
				</div>
			)
		}
	];

	/**
	 * Method called once the edit icon button its clicked
	 * @param id
	 */
	const handleEdit = (id: string) => {
		setGlobalBannerFormTitle('Edit Banner');
		globalBannerFormService.current.setBannerId(id);
		globalBannerFormService.current.setBannerPanelOpen(true);
	};

	/**
	 * Method called once the delete icon button its clicked
	 * @param id
	 * @param bannerName
	 */
	const handleRemove = (id: string) => {
		setIsOpenDeleteBannerConfirmation(true);
		setBannerIdToDelete(id)
	};

	const onCloseBannerManagerDeleteConfirmation = () => {
		setIsOpenDeleteBannerConfirmation(false);
	}

	/**
	 * Method called to redirect user to create banner form
	 */
	const handleAddBanner = () => {
		setGlobalBannerFormTitle('Create Banner');
		globalBannerFormService.current.setBannerPanelOpen(true);
	};

	/**
	 * Calling API endpoint to delete banner and show fresh list back
	 */
	const onDeleteConfirmation = () => {
		if(bannerIdToDelete) {
			globalBannerDataAPI.current.deleteBannerImage(bannerIdToDelete).then((result) => {
				if(result) {
					setIsOpenDeleteBannerConfirmation(false);
					globalBannerDataAPI.current.getDashboardBannerImages().then((result) => {
						if (!Array.isArray(result)) return;
						globalBannerFormService.current.setBannersList(result)
					})
				}
			})
		}
	};

	/**
	 * Gets Banners data to show into dashboard items
	 */
	async function getBannersData() {
		await globalBannerDataAPI.current.getDashboardBannerImages()
			.then((result) => {
				if (!Array.isArray(result)) return;
				globalBannerFormService.current.setBannersList(result)
				setBannersDataReady(true);
			})
			.catch((ex) => {
				// TODO: Handle error
			});
	}

	useEffect(() => {
		getBannersData()
	}, []);


	/**
	 * Loading temp component to show shimmer while list its loading
	 * @returns
	 */
	const renderLoading = () => {
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-12 col-xl-12" tabIndex={0} aria-label="Global Banners List">
					<ListShimmer liquid/>
				</div>
			</div>
		);
	};

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (!requested && (requesting || isManageListLoading)) {
		return renderLoading();
	}

	/**
	 * Exit action on Change Space feature cancel button
	 */
	const dismissGlobalBannerPanel = (): void => {
		globalBannerFormService.current.resetBannerId();
		globalBannerFormService.current.resetData();
		globalBannerFormService.current.setBannerPanelOpen(false);
	};

	if (isSuperAdmin)
		return (
			<>
				<div className={`row`}>
					<div className="col-md-12 col-lg-6 col-xl-8" tabIndex={0} aria-label="Manage Global Banner">
						<div className="global-banner-manager-container">
							<div className="global-banner-manager-header">
								<h1 aria-label="Manage Global Banner" role="heading" tabIndex={0}>
									Manage Global Banner
								</h1>
								<IconButton
									text="Create Banner"
									iconName="CircleAddition"
									onClick={handleAddBanner}
									className="global-banner-manager-add-button"
								/>
							</div>
							<div className="global-banner-manager-list">
							{bannersDataReady ? (
								processedBannerItems && processedBannerItems.length > 0 ? (
									<DetailsList
										items={processedBannerItems}
										columns={columns}
										setKey="set"
										layoutMode={0}
										selectionMode={SelectionMode.none}
										ariaLabelForSelectionColumn="Toggle selection"
										ariaLabelForGrid="Global Banner Manage List"
										focusZoneProps={{
											allowTabKey: true
										}}
										isHeaderVisible={true}
									/>
								) : (
									<p>No Banner Items to show</p>
								)
							) : (
								renderLoading()
							)}
							</div>
						</div>
					</div>
				</div>
				<ConfirmationMessage
					isOpen={isOpenDeleteBannerConfirmation}
					onClose={onCloseBannerManagerDeleteConfirmation}
					onAction={onDeleteConfirmation}
					title={"Delete Banner"}
					text={"Are you sure you want to delete this banner?"}
				/>
				<CustomPanel
					isOpen={isBannerPanelOpen}
					onDismiss={dismissGlobalBannerPanel}
					headerText={globalBannerFormTitle}
				>
					<GlobalBannerForm />
				</CustomPanel>
			</>
		);

	if (!isSuperAdmin && isManageBannerListLoaded && !isManageBannerListLoading) {
		return renderUnauthorized();
	}
};

export default GlobalBannerManage;