// Necessary interface imports
import { RouteObject } from "react-router-dom";
import { RoutePathStrings } from "./applicationRoutePaths";
import EventEditPage from "../../events/edit.event";
// import EventCheckIn from "../../event/event.checkin";
// import Event from "../../event/event";
import EventSearchPage from "../../events/search.event";
import EventViewPage from "../../events/view.event";
import EventSettingsPage from "../../events/settings.event";
import EventsCheckInPage from "../../events/checkin.event";
import EventsAdminCheckInsPage from "../../events/checkins.admin.event";

/**
 * Event Routes Map.
 * Used internally to organize groups of similar RouteObjects.
 */
export const EventRoutes: RouteObject[] = [
	// View/Publish Event
	{
		path: RoutePathStrings.event,
		Component: EventViewPage
	},
	// Search Event
	{
		path: RoutePathStrings.eventSearch,
		Component: EventSearchPage
	},
	// Create Event
	{
		path: RoutePathStrings.eventCreate,
		Component: EventEditPage
	},
	// Edit Event
	{
		path: RoutePathStrings.eventEdit,
		Component: EventEditPage
	},
	// Event Settings
	{
		path: RoutePathStrings.eventSettings,
		Component: EventSettingsPage
	},
	// Single Event Original view
	// {
	// 	path: RoutePathStrings.eventRegistration,
	// 	Component: Event
	// },
	// Event Check-in
	{
		path: RoutePathStrings.eventCheckIn,
		Component: EventsCheckInPage
	},
	// Event Check-ins all admin o see processed files
	{
		path: RoutePathStrings.eventCheckIns,
		Component: EventsAdminCheckInsPage
	}
];
