import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceHub } from "../../service";
import { authSelectors } from "../../redux/selectors/auth";
import {
	setAccessRequested,
	setAccessRequesting,
	setCanAccessCreateArticleComponents,
	setIsSuperAdmin,
	setSuperAccessRequested,
	setSuperAccessRequesting
} from "../../redux/reducers/auth";
import store from "../../redux/store";
import { appManagementSelectors } from "../../redux/selectors/appManagement";

/**
 * Defines the return types for Super Admin Access Control hook.
 */
export interface ISuperAccessControlInfo {
	isSuperAdmin: boolean;
	userId: string;
	requesting: boolean;
	requested: boolean;
}

/**
 * Checks Super Admin role functions.
 *
 * @returns Super Admin Access Control object result.
 */
export const useSuperAdminAccessControl = (): ISuperAccessControlInfo => {
	const userId = useSelector(authSelectors.getUserId);
	const isSuperAdmin = useSelector(authSelectors.getIsSuperAdminAccess);
	const requestingSuperAccess = useSelector(authSelectors.getSuperAccessRequesting);
	const requestedSuperAccess = useSelector(authSelectors.getSuperAccessRequested);

	return { isSuperAdmin, userId, requesting: requestingSuperAccess, requested: requestedSuperAccess };
};
