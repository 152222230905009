import React, { useEffect, useRef } from "react";

import LoaderIndicator from "../../loaderIndicator/loaderIndicator";
import { Message } from "../../common/message";
import { ToastContainer } from "react-toastify";
import { ServiceHub } from "../../../service";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../redux/selectors/auth";
import { setAccessRequested, setAccessRequesting, setIsSuperAdmin, setSuperAccessRequested, setSuperAccessRequesting } from "../../../redux/reducers/auth";
import store from "../../../redux/store";

/**
 * Strike Layout Shared components.
 * Includes mainstream utilities below:
 * - Loader Indicator
 * - Message Modal
 * - Toast Container (quick feedbacks)
 *
 * @returns A Function component to be used by Layout main component.
 */
export const LayoutSharedComponents: React.FC = () => {
	const appMenuSvc = useRef(ServiceHub.appMenuAPI.start());
	const dispatch = useDispatch();
	const superAdminService = useRef(ServiceHub.superAdminAPI.start());
	const requestingSuperAccess = useSelector(authSelectors.getSuperAccessRequesting);
	const requestedSuperAccess = useSelector(authSelectors.getSuperAccessRequested);
	const service = useRef(ServiceHub.nodeAdminAPI.start());
	const requestingAccess = useSelector(authSelectors.getAccessRequesting);
	const requestedAccess = useSelector(authSelectors.getAccessRequested);
	

	useEffect(() => {
		appMenuSvc.current.checkAccess();
	}, []);

	useEffect(() => {
		/**
		 * Requests the service for Access for Super Admin role,
		 */
		async function checkSuperAccess() {
			if (!requestingSuperAccess && !requestedSuperAccess) {
				dispatch(setSuperAccessRequesting(true));
	
				try {
					if (superAdminService.current) {
						const result = await superAdminService.current.reviewAccess();
						store.dispatch(setIsSuperAdmin(result));
						ServiceHub.appManagementAPI.setIsUserSuperAdminAccess(result);
					} else {
						store.dispatch(setIsSuperAdmin(false));
					}
				} catch (error) {
					console.error("Error checking super admin access:", error);
					store.dispatch(setIsSuperAdmin(false));
				} finally {
					dispatch(setSuperAccessRequested(true));
					dispatch(setSuperAccessRequesting(false));
				}
			}
		}
	
		checkSuperAccess();
	}, [superAdminService, requestingSuperAccess, requestedSuperAccess]);
	
	useEffect(() => {
		/**
		 * Requests the service for Access to Node Admin functions,
		 * which will consume data from api.
		 */
		async function checkAccess() {
			if (!requestingAccess && !requestedAccess) {
				dispatch(setAccessRequesting(true));
	
				try {
					const result = await service.current.reviewAccess();
					ServiceHub.appManagementAPI.setIsUserNodeAdminAccess(result);
				} catch (error) {
					console.error("Error checking node admin access:", error);
				} finally {
					dispatch(setAccessRequested(true));
					dispatch(setAccessRequesting(false));
				}
			}
		}
	
		checkAccess();
	}, [service, requestingAccess, requestedAccess]);

	return (
		<>
			<ToastContainer />
			<LoaderIndicator />
			<Message />
		</>
	);
};
