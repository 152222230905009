import React from "react";
import { Segment, Header, Icon, SemanticICONS } from "semantic-ui-react";

type Props = {
	header: string;
	message: string | JSX.Element;
	iconName?: SemanticICONS;
	isHtml?: boolean;
};

const ErrorPage: React.FC<Props> = (props) => {
	const icon = props.iconName || "frown";
	return (
		<Segment placeholder textAlign="center" size="big" className="not-found-not-access">
			<Header icon>
				<Icon name={icon} color="blue" />
				{props.header}
			</Header>
			{props.isHtml ? <div dangerouslySetInnerHTML={{ __html: `${props.message}` }} /> : props.message}
		</Segment>
	);
};

export default ErrorPage;
