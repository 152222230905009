import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { globalBannerFormSelectors } from "../../redux/selectors/globalBannerForm";
import { IGlobalBannerFormData, IGlobalBannerItemsResponse } from "../../contracts";
import { ServiceHub } from "../../service";
import { validateUrl } from "../../lib/strikeLibrary";

/**
 * Contract for the Global Banner Form hook.
 */
interface IGlobalBannerFormHook {
	isNewGlobalBanner: boolean;
    globalBannerFormData: IGlobalBannerFormData;
    canSubmit: boolean;
	processedBannerItems: IGlobalBannerItemsResponse[];
	isBannerPanelOpen: boolean;
	bannerId: string;
}

/**
 * Global Banner Form Validation properties shape
 */
export interface IGlobalBannerFormValidation {
	[key: string]: boolean;
	id?: boolean;
	title: boolean;
	link: boolean;
}

/**
 * Hook to handle and processing Global Banner Form data.
 * @returns ISpaceFormHook
 */
export const useGlobalBannerForm = (): IGlobalBannerFormHook => {
    const globalBannerFormData = useSelector(globalBannerFormSelectors.getData);
	const isBannerPanelOpen = useSelector(globalBannerFormSelectors.getBannerPanelOpen);
	const AppFileApi = useRef(ServiceHub.appFileAPI.start());

	/**
	 * Differentiates between New and Edit Global Banner
	 */
	const isNewGlobalBanner = useMemo(() => {
		return !globalBannerFormData?.id;
	}, [globalBannerFormData]);

	// useEffect(() => {

	// }, [isLoaded, isLoading]);

    const validation = useMemo<IGlobalBannerFormValidation>(() => {
		return {
			title: globalBannerFormData.title !== null &&  globalBannerFormData.title !== "",
			link: globalBannerFormData.link !== "",
			bannerImageFile: globalBannerFormData.bannerImageFile !== null,
			bannerImageMimeType: globalBannerFormData.bannerImageMimeType !== null,
			isUrlValid : validateUrl(globalBannerFormData.link)
		};
	}, [globalBannerFormData]);

	const bannerItems = useSelector(globalBannerFormSelectors.getBannersList);
	const bannerId = useSelector(globalBannerFormSelectors.getBannerId);

    const canSubmit = useMemo(() => validation.title && validation.link && validation.bannerImageFile && validation.bannerImageMimeType && validation.isUrlValid || bannerId, [validation]);

	const [processedBannerItems, setProcessedBannerItems] = useState([]);

    useEffect(() => {
        const processBanners = async () => {
            if (bannerItems) {
                const processedBanners = await Promise.all(bannerItems.map(async (banner) => {
                    if (banner.BannerImageName) {
                        try {
                            const BannerImageData = await getBannerImageData(banner.BannerImageName);
                            return { ...banner, BannerImageData };
                        } catch (error) {
                            return banner; // Return the original banner if there's an error
                        }
                    }
                    return banner;
                }));
                setProcessedBannerItems(processedBanners);
            }
        };

        processBanners();
    }, [bannerItems]);

    /**
     * Convert string urls to Blob
     * @param imagePath 
     * @returns 
     */
    const getBannerImageData = async (imagePath: string) => {
        try {
            const result = await AppFileApi.current.getBannerImageByURL(imagePath);
            const imageData = result ? URL.createObjectURL(result) : null;
            return imageData;
        } catch (error) {
            return null;
        }
    };


	return {
		isNewGlobalBanner,
        globalBannerFormData,
        canSubmit,
		processedBannerItems,
		isBannerPanelOpen,
		bannerId
	} as IGlobalBannerFormHook;
};
