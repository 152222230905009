/**
 * Redux config to manage space Form data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { IEventAllowListResponse, IEventFormData, IEventFormDataResponse } from "../../contracts/event/eventForm";
import { eventFormInitialState } from "../../models/slices/event/eventFormInitialState";

/**
 * Event Form state management layer, also named after a Redux Slice.
 */
export const eventFormSlice = createSlice({
	name: AppSlices.eventForm,
	initialState: eventFormInitialState,
	reducers: {
		setData: (state, action: PayloadAction<IEventFormData>) => {
			state.data = action.payload;
		},
		resetData: (state) => {
			state.data = eventFormInitialState.data;
		},
		setBodySourceMode: (state, action: PayloadAction<boolean>) => {
			state.bodySourceMode = action.payload;
		},
		setSelectedEventTypeId: (state, action: PayloadAction<null | number | string>) => {
			state.selected.eventTypeId = action.payload;
		},
		setSelectedEventIdToCheckin: (state, action: PayloadAction<null | number | string>) => {
			state.checkin.eventSelectedId = action.payload;
		},
		setSelectedStartDate: (state, action: PayloadAction<any>) => {
			state.selected.startDate = action.payload;
		},
		setSelectedEndDate: (state, action: PayloadAction<any>) => {
			state.selected.endDate = action.payload;
		},
		setSelectedPublishDate: (state, action: PayloadAction<any>) => {
			state.selected.publishDate = action.payload;
		},
		setEventsList: (state, action: PayloadAction<IEventFormDataResponse[]>) => {
			state.events.list = action.payload;
		},
		setEventAllowedUserList: (state, action: PayloadAction<IEventAllowListResponse[]>) => {
			state.event.allowedUsers = action.payload;
		},
		resetEventAllowedUserList: (state) => {
			state.event.allowedUsers = eventFormInitialState.event.allowedUsers;
		},
		removeUserFromAllowedList: (state, action: PayloadAction<string>) => {
			state.event.allowedUsers = state.event.allowedUsers.filter((item) => item.emailId !== action.payload);
		},
		setIsAllowUsersPanelOpen: (state, action: PayloadAction<boolean>) => {
			state.ui.allowedUsersPanelOpen = action.payload;
		},
		setIsProcessFilePanelOpen: (state, action: PayloadAction<boolean>) => {
			state.ui.processFilePanelOpen = action.payload;
		},
		setIsAllowUsersChanged: (state, action: PayloadAction<boolean>) => {
			state.ui.allowedUsersChanged = action.payload;
		},
		setIsOpenDeleteEventModal: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenDeleteEventModal = action.payload;
		},
		setIsOpenPublishEventModal: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenPublishEventModal = action.payload;
		},
		setIsEventListLoaded: (state, action: PayloadAction<boolean>) => {
			state.ui.isEventListLoaded = action.payload;
		},
		setIsEventListLoading: (state, action: PayloadAction<boolean>) => {
			state.ui.isEventListLoading = action.payload;
		},
		setEventFilterSearchText: (state, action: PayloadAction<string>) => {
			state.filter.eventFilterSearchText = action.payload;
		},
		setEventFilterSearchType: (state, action: PayloadAction<string>) => {
			state.filter.eventFilterSearchType = action.payload;
		},
		setEventFilterSearchTypeOption: (state, action: PayloadAction<string>) => {
			state.filter.eventFilterSearchTypeOption = action.payload;
		},
		setEventFilterSearchStatus: (state, action: PayloadAction<string>) => {
			state.filter.eventFilterSearchStatus = action.payload;
		},
		setEventFilterSearchStatusId: (state, action: PayloadAction<string>) => {
			state.filter.eventFilterSearchStatusId = action.payload;
		}
	}
});

export const eventFormActions = eventFormSlice.actions;

export default eventFormSlice.reducer;
