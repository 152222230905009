import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps, Segment } from "semantic-ui-react";
import { TextInput } from "../common/text";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { IMSGraphUserBasic, IStrikeNodeEntity } from "../../contracts/models";
import StrikeNode from "../../models/StrikeNode";
import { Dropdown } from "../common/dropdown";
import { ServiceHub } from "../../service";
import { Button } from "../common/button";
import { Shimmer } from "../common/shimmer";
import { createAuthoriableUser, getQueryCreateAuthUser } from "../../lib/strikeLibrary";
import { useAuthUser } from "../../hooks";
import { ApplicationRoutePaths } from "../router";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { ListShimmer } from "../common/shimmer/list/shimmer.list";
import { UnauthorizedPage } from "../common/page";
import { FormField } from "../common/form";

/**
 * Controls the props of Article Viewer component
 */
interface IArticleAuthorUpdateProps {}

/**
 * New Author Form Validation properties shape
 */
export interface INewAuthorFormValidation {
	[key: string]: boolean;
	id?: boolean;
	newAuthor: boolean;
}

/**
 * Article Author Updat component.
 *
 * @param props IArticleAuthorUpdateProps
 * @returns React.FunctionComponent<IShowArticleProps>
 */
export const ArticleAuthorUpdate: React.FunctionComponent<IArticleAuthorUpdateProps> = (props) => {
	const params = useParams();
    const [nodeDetails, setNodeDetails] = useState<IStrikeNodeEntity>(new StrikeNode(0, "", "", ""));
    const [currentSearch, setCurrentSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isArticleDataLoading, setIsArticleDataLoading] = useState<boolean>(false);
    const [authorUsersItems, setAuthorUsersItems] = useState<null | DropdownItemProps[]>([]);
    const [userList, setUserList] = useState<DropdownItemProps[]>([]);
    const resolvedOptions: DropdownItemProps[] = [...authorUsersItems, ...userList];
    const [userResetKey, setUserResetKey] = useState<number>(0);
    const [userToAuthor, setUserToAuthor] = useState<null | string>('');
    const [userNameToAuthor, setUserNameToAuthor] = useState<null | string>('');
    const graphAPI = useRef(ServiceHub.graphAPI.start());
    const superAdminAPI = useRef(ServiceHub.superAdminAPI.start());
    const [announcement, setAnnouncement] = useState("");
    const searchTimeout = useRef<NodeJS.Timeout>(null);
    const [isNotANumber, setIsNotANumber] = useState(false)
    const [newAuthorUpn, setNewAuthorUpn] = useState("");
    const [currentAuthorUpn, setCurrentAuthorUpn] = useState("");
    const { userPrincipalName } = useAuthUser();
    const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
    const [isAuthorChanged, setIsAuthorChanged] = useState(false)
    const [articleIdValue, setArticleIdValue] = useState('')

    /**
     * Get Node Details by specific Id
     */
    const getNodeDetails = useCallback(async (id) => {
        setIsArticleDataLoading(true)
        try {
            const res = await NodeAPIServiceInstance.getById(Number(id));
            if (res.Id) {
                setNodeDetails(res);
                setIsArticleDataLoading(false)
                setCurrentAuthorUpn(res.AuthEmail)
            }
        } catch (error) {
            console.error("Error getting the details of the Article", error);
        }
    }, [params.id, NodeAPIServiceInstance]);

    
    /**
     * Utiliy function to manage id number trigger to get data
     * @param func 
     * @param wait 
     * @returns 
     */
    const debounce = (func: any, wait: number) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

    /**
     * Setting Article to change Author by Id number
     */
    const onArticleIdValue = debounce((event) => {
        if (/^\d*$/.test(event.target.value)) {
            setIsNotANumber(false)
            getNodeDetails(event.target.value);
        } else {
            setIsNotANumber(true)
        }
        
    }, 1000);


    /**
     * Handles the event of changing the Search text,
     * When querying users from MS Graph.
     *
     * @param event The event happening.
     * @param data The Search Change event state.
     */
    const onSearchChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownOnSearchChangeData
    ): void => {
        const search = data.searchQuery;
        setCurrentSearch(search);
    };

    /**
     * Handles the On Change event from the User to Share With.
     *
     * @param event The event happening
     * @param data The instant DropdownProps state
     */
    const onChangeUser = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
        const dropdownItem = data.options.find((option) => data.value === option.value);		
        setUserToAuthor(data.value.toString());
        setUserNameToAuthor(dropdownItem.title);
        const selectedUserUpn = data.value.toString()
        const selectedUserId = selectedUserUpn.split("@");
        setNewAuthorUpn(selectedUserUpn)
        let authoriableUser = getQueryCreateAuthUser(selectedUserId[0], dropdownItem.title, selectedUserUpn);
        createAuthoriableUser(authoriableUser);
    };


    /**
     * Prepares the MS Graph User data to be bound to the
     * DropdownOptions.
     *
     * @param users The user collection to process.
     * @returns The converted DropdownOptions.
     */
    const onConvertGraphUsersToDropdownOptions = (users: IMSGraphUserBasic[]) => {
        if (undefined === users || users === null || !Array.isArray(users)) return;
        const dropdownOptions = users.map((item): DropdownItemProps => {
            const userNameUpn = `${item.displayName} <${item.userPrincipalName}>`;

            return {
                key: item.userPrincipalName,
                title: item.displayName,
                text: userNameUpn,
                "aria-label": `Add user: ${item.displayName}`,
                value: item.userPrincipalName
            } as DropdownItemProps;
        });

        setUserList(dropdownOptions);
    };

    /**
	 * When Searching the users is required,
	 * This method handles the processing.
	 * And check if there are results
	 */
	const onSearch = async () => {
		setIsLoading(true);
		try {
			const searchResults = await graphAPI.current.searchUsers(currentSearch);

			onConvertGraphUsersToDropdownOptions(searchResults);

			if (!searchResults || searchResults.length === 0) {
				setAnnouncement("No results found.");
			} else {
				setAnnouncement("");
			}
		} catch (error) {
			console.error("Error al buscar usuarios:", error);
		} finally {
			setIsLoading(false);
		}
	};

    useEffect(() => {
        async function delayedSearch() {
            // Forcing a clean-up before a new timeout, since
            // It generates debounce effect
            if (searchTimeout.current !== null) clearTimeout(searchTimeout.current);

            searchTimeout.current = setTimeout(() => {
                onSearch();
            }, 500);
        }

        // Pre-processes the query, if specified
        delayedSearch();
    }, [currentSearch]);


    /**
     * Hit API to update article author based on object shape
     */
    const updateAuthor = () => {
        let authorUpdateObject = {nodeId: nodeDetails.Id, newAuthorUPN: newAuthorUpn, currentAuthorUPN: currentAuthorUpn, loginUserUPN: userPrincipalName }
        superAdminAPI.current.updateArticleAuthor(authorUpdateObject).then((result: any) => {
            if (result instanceof Error) {
                ServiceHub.message.error(result.message);
                throw result;
            }

            setIsAuthorChanged(true);
            resetForms();
        
            if (!result) {
                // Redirect the user to the dashboard
                ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
                return;
            }
        
        });
    }

    /**
     * validation data
     */
    const validation = useMemo<INewAuthorFormValidation>(() => {
		return {
			id: nodeDetails.Id !== null && nodeDetails.Id !== 0,
			newAuthor: userNameToAuthor !== ""
		};
	}, [nodeDetails, userNameToAuthor]);

    const canSubmit = useMemo(() => validation.id && validation.newAuthor , [validation]);

    const resetForms = () => {
        setUserResetKey(userResetKey + 1);
        setArticleIdValue('');
        setUserToAuthor('');
        setUserNameToAuthor('');
        const inputId = (document.getElementById('articleId') as HTMLInputElement);
        inputId.value = '';
        setNodeDetails(new StrikeNode(0, "", "", ""));
    }
    /**
     * Loading temp component to show shimmer while list its loading
     * @returns
     */
    const renderLoading = () => {
        return (
            <div className={`row`}>
                <div className="col-md-12 col-lg-12 col-xl-12" tabIndex={0} aria-label="Global Banners List">
                    <ListShimmer liquid/>
                </div>
            </div>
        );
    };

    /**
     * Shows unauthorized component
     * @returns
     */
    const renderUnauthorized = () => {
        return <UnauthorizedPage />;
    };

    if (!requested && (requesting)) {
        return renderLoading();
    }

    if (isSuperAdmin)
	return (
		<React.Fragment>
			<Segment basic className="strike-node-wrapper">
                <div className="article-author-update-form">
                    <h2 aria-label="Update Article Author"> Update Article Author</h2>
                    <Divider />
                    <div className="article-author-update-form-data-wrapper">
                        <div className="article-author-update-form-data-input">
                            <label htmlFor="articleId">
                                <strong>Article Id:</strong>
                            </label>

                            <TextInput id="articleId" onChange={onArticleIdValue} placeholder="Search Article by Id"  />
                            {isNotANumber ? <p className="article-update-author-id-validation">Id needs to be a Number</p> : null}

                            <div className="article-author-update-form-data-info">
                                {nodeDetails && nodeDetails.Title && nodeDetails.AuthName ? (
                                    <div>
                                        <p aria-label="Article Title Info"><strong>Article Title:</strong> {nodeDetails.Title}</p>
                                        <p aria-label="Article Title Info"><strong>Article Current Author:</strong> {nodeDetails.AuthName}</p>
                                    </div> )
                               : isArticleDataLoading ? <><p className="loading-article-data">Loading Article data...</p><Shimmer width="100%" className="loading-article-data" /> <Shimmer width="100%" className="loading-article-data" /></> : null}
                            </div>
                        
                            <FormField
                                id="newAuthor"
                                label="New Author:"
                                value={userToAuthor}
                            >
                            <Dropdown
                                key={userResetKey}
                                fluid
                                search
                                selection
                                multiSelect={false}
                                id="newAuthor"
                                selectedValue={userToAuthor}
                                options={resolvedOptions}
                                onChange={onChangeUser}
                                onSearchChange={onSearchChange}
                                ariaLabel="Select Article new Author"
                                placeholder="Select Article New Author"
                                renderLabel={(item) => {
                                    return item.title;
                                }}
                                closeOnEscape
                                closeOnBlur
                                useEnhancements
                                allowAdditions={false}
                            />
                            </FormField>
                            <div className="article-update-author-button">
                                <Button text="Update Author" onClick={updateAuthor} disabled={!canSubmit} />
                            </div>

                            {isAuthorChanged ? <div className="alert alert-success article-author-updated-success"> Article Author updated successfully!</div> : null}
                        </div>
                    </div>
                </div>
			</Segment>
		</React.Fragment>
	);

    if (!isSuperAdmin) {
		return renderUnauthorized();
	}
};
