import React, { useEffect } from "react";
import { EventCheckInDetails } from "./shared/event.show.event.checkin";

/**
 * Component Event Check-In to handle Events Check-In actions.
 */
const EventsCheckInPage: React.FC<{}> = () => {
	useEffect(()=>{
		document.title='Event Check-In - Strike Community';
	},[])
	return <EventCheckInDetails />;

};

export default EventsCheckInPage;
