/**
 * Redux config to manage space Form data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { topicsExpertsInitialState } from "../../models/slices/topics/topicsExpertsInitialState";
import { ITopicsExpertsResponse } from "../../contracts/topic/ITopicsExpertResponse";

/**
 * Article Form state management layer, also named after a Redux Slice.
 */
export const topicsExpertsSlice = createSlice({
    name: AppSlices.topicsExperts,
    initialState: topicsExpertsInitialState,
    reducers: {
        setTopicsExperts: (state, action: PayloadAction<ITopicsExpertsResponse[]>) => {
            state.data = action.payload;
        },
        resetTopicsExperts: (state) => {
            state.data = topicsExpertsInitialState.data;
        }
    }
});

export const {
    setTopicsExperts,
    resetTopicsExperts
} = topicsExpertsSlice.actions;

export default topicsExpertsSlice.reducer;