import { INavLink, INavLinkGroup } from "@fluentui/react";
import { SystemMessageConfig } from "../../contracts";
import { IHeaderMenuItem } from "../../contracts/appMenu";
import { MessageType } from "../../enums";
import { ServiceHub } from "../../service";
import { createSelector } from "@reduxjs/toolkit";
import { IStrikeNodeSticky } from "../../contracts/models";

/**
 * creating id based on title, id or timestamp
 * @param item
 * @param index
 * @returns
 */
const getItemId = (item: any, index: number = 1) => {
	const currentTimeStamp = new Date().getTime();
	let idOrTitle = currentTimeStamp;

	if (item) {
		if (item?.Id && item?.Id !== null) {
			idOrTitle = item.Id;
		} else if (item?.Title) {
			idOrTitle = item?.Title?.replaceAll(" ", "_");
		}
	}

	const newItemId = `${idOrTitle}_${index}`;
	return newItemId;
};

const processItems = (parentItem, elementList) => {
	if (parentItem.Items) {
		parentItem.Items.filter((item) => item.Divider === false).forEach((childItem, index) => {
			elementList.push({ ...childItem, Id: getItemId(childItem, index), ParentId: parentItem.Id });
		});
	}
	delete parentItem.Items;
};

/**
 * Definition of selectors
 */
export const appManagementSelectors = {
	getLoaderVisible: (state) => {
		return state.appManagement.loader.visible;
	},
	getLoaderText: (state) => state.appManagement.loader.text,
	getIsEventAdminUser: (state) => state.appManagement.userAccess.eventAdmin,
	getIsNodeAdminUser: (state) => state.appManagement.userAccess.nodeAdmin,
	getIsSuperAdminUser: (state) => state.appManagement.userAccess.superAdmin,
	getAppMenuData: (state) => state.appManagement.menu.data,
	layout: (state) => state.appManagement.layout.layoutType,
	getBannerVisible: (state) => state.appManagement.banner.visible,
	getMessage: (state): SystemMessageConfig => state.appManagement.message,
	getMessageText: (state): string => state.appManagement.message.text,
	getMessageType: (state): MessageType => state.appManagement.message.type,
	getMessageOpen: (state): boolean => state.appManagement.message.open,
	getMessageIsHtml: (state): boolean => state.appManagement.message.isHtml,
	getIsHighZoomLevel: (state): boolean => state.appManagement.layout.isHighZoomLevel,
	getIsMenuItemExpandedById: (state, menuItemId: string): boolean =>
		state.appManagement.menu.expanded?.includes(menuItemId),
	getMenuItemSelected: (state): string => state.appManagement.menu.selectedId,
	getSelectedIds: (state): string[] => {
		return state.appManagement.menu.selectedIds;
	},
	getParentById: (state, parentId: string): string => {
		const parentItem = state.appManagement.menu.data?.Items.filter((item) => item.Id === parentId);
		return parentItem;
	},
	getNormalizedList: createSelector(
		(state) => state,
		(state): any[] => {
			const elementList = [];
			const rootList = [];

			// creating normalized from original to fill and create undefined fields
			state.appManagement.menu.data?.Items.forEach((rootItem, index) => {
				rootList.push({ ...rootItem, Id: getItemId(rootItem, index) });
			});

			rootList.forEach((parentItem) => {
				processItems(parentItem, elementList);
			});

			elementList.forEach((parentItem) => {
				processItems(parentItem, elementList);
			});
			return [...rootList, ...elementList];
		}
	),
	getTransformedNavLinks: createSelector(
		(state) => state,
		(_, hasEventAccess: boolean) => hasEventAccess,
		(state, hasEventAccess: boolean): INavLinkGroup[] => {
			const transformToNavLinks = (item: IHeaderMenuItem): INavLinkGroup => {
				const transform = (childItems: IHeaderMenuItem[]): INavLink[] => {
					const selectChains = appManagementSelectors.getSelectedIds(state);

					return childItems
						.filter((childItem) => childItem.Divider === false)
						.map((childItem, index) => {
							// Removes unauthorized items, until authorization is provided
							if (childItem?.Authorize && !hasEventAccess) return null;

							const newItemId = getItemId(childItem, index);

							return {
								key: newItemId,
								name: childItem.Title,
								url: childItem.Route,
								expandAriaLabel: `Show more: ${childItem.Title}`,
								links: childItem.Items?.length ? transform(childItem.Items) : null,
								isExpanded: selectChains.includes(newItemId)
							} as INavLink;
						})
						.filter((item) => item !== null);
				};

				// Removes unauthorized items, until authorization is provided
				if (item?.Authorize && !hasEventAccess) return null;

				const isItemGroupExpanded = appManagementSelectors.getIsMenuItemExpandedById(state, item.Id);

				return {
					onHeaderClick: () => {
						ServiceHub.appMenuAPI.start().resetAllMenu();
						ServiceHub.appMenuAPI.start().resetSubMenus();
						ServiceHub.appMenuAPI.start().setExpandedMenuItem(item.Id, !isItemGroupExpanded);
					},
					isExpanded: isItemGroupExpanded,
					name: item.Title,
					collapseByDefault: true,
					collapseAriaLabel: item.Title,
					expandAriaLabel: item.Title,
					links: item.Items?.length ? transform(item.Items) : null
				};
			};

			return !state.appManagement.menu?.data?.Items
				? []
				: state.appManagement.menu.data?.Items.map((rootMenuItem) => transformToNavLinks(rootMenuItem)).filter(
						(item) => item !== null
				  );
		}
	),
	getStickyNodes: (state): IStrikeNodeSticky[] => state.appManagement.stickyNodes,
};
