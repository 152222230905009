import { ITreeItem } from "@coherence-design-system/tree-view";
import { IEventAllowListResponse, IEventFormData, IEventFormDataResponse } from "../../contracts/event/eventForm";
import { createSelector } from "@reduxjs/toolkit";
import { IEventTypeItem, ISelectedEventTypeItem } from "../../contracts/slices/event/eventTypeItem";
import { ServiceHub } from "../../service";
import { createSpaceInValue } from "../../lib/strikeLibrary";

export const eventTypesValues = [
	{
		Id: 1,
		Title: "Virtual"
	},
	{
		Id: 2,
		Title: "InPerson"
	},
	{
		Id: 3,
		Title: "Mixed"
	}
];

export const eventStatusValues = [
	{
		id: "1",
		title: "Completed"
	},
	{
		id: "2",
		title: "Ongoing"
	},
	{
		id: "3",
		title: "Not Started"
	}
];

/**
 * Event Form data access selectors.
 */
export const eventFormSelectors = {
	getData: (state): IEventFormData => {
		return state.eventForm.data;
	},
	getBodySourceMode: (state): boolean => state.eventForm.bodySourceMode,
	getSelectedEventType: createSelector(
		(state) => state.eventForm.selected.eventType,
		(item): ISelectedEventTypeItem | null => {
			if (item) {
				return {
					id: item.id,
					text: item.title
				};
			}
			return null;
		}
	),
	getSelectedEventToCheckin: createSelector(
		(state) => state.eventForm.checkin.eventSelected,
		(item): ISelectedEventTypeItem | null => {
			if (item) {
				return {
					id: item.id,
					text: item.title
				};
			}
			return null;
		}
	),
	getEventTypes: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return eventTypesValues.map((item) => eventFormSelectors.convertEventTypesToOptions(state, item));
		}
	),
	convertEventTypesToOptions: createSelector(
		(state) => state,
		(_, item: IEventTypeItem) => item,
		(state, item: IEventTypeItem): ITreeItem => {
			return {
				id: item.Id,
				title: item.Title,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					ServiceHub.eventForm.start().setSelectedEventTypeId(treeItem.id);
				}
			} as ITreeItem;
		}
	),
	getStatusTypes: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return eventStatusValues.map((item) => eventFormSelectors.convertEventStatusToOptions(state, item));
		}
	),
	convertEventStatusToOptions: createSelector(
		(state) => state,
		(_, item: ITreeItem) => item,
		(state, item: ITreeItem): ITreeItem => {
			return {
				id: item.id,
				title: item.title,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					ServiceHub.eventForm.start().setSelectedEventStatusId(treeItem.id);
				}
			} as ITreeItem;
		}
	),
	getEventSelectList: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			const eventsList = state.eventForm.events.list || [];
			const currentDate = new Date();
			const activeEventsList = eventsList.filter((event) => currentDate < new Date(event.DateEnd));
			const orderedActiveEventsList = activeEventsList.sort((a,b) => new Date(a.DateStart).getTime() - new Date(b.DateStart).getTime())
			if (eventsList.length > 0) {
				return orderedActiveEventsList.map((item) => eventFormSelectors.convertEventSelectListToOptions(state, item));
			}
			return [];
		}
	),
	getAllEventList: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			const eventsList = state.eventForm.events.list || [];
			if (eventsList.length > 0) {
				return eventsList.map((item) => eventFormSelectors.convertEventSelectListToOptions(state, item));
			}
			return [];
		}
	),
	convertEventSelectListToOptions: createSelector(
		(state) => state,
		(_, item: IEventTypeItem) => item,
		(state, item: IEventTypeItem): ITreeItem => {
			return {
				id: item.Id,
				title: item.Title,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					ServiceHub.eventForm.start().setSelectedEventIdToCheckin(treeItem.id);
				}
			} as ITreeItem;
		}
	),
	getEventSelectedItem: createSelector(
		(state) => state,
		(state) => state.eventForm.checkin.eventSelectedId,
		(state, selectedId) => {
			let eventById;
			const selectedEventId = selectedId;

			if (!selectedEventId) return null;

			eventById = eventFormSelectors.getEventById(state, selectedEventId);

			if (!eventById || eventById?.Title === "") return null;

			return {
				id: selectedEventId.toString(),
				text: eventById?.Title
			};
		}
	),
	getEventById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): IEventFormDataResponse => {
			return state.eventForm.events.list.find((item) => item.Id === itemId);
		}
	),
	getEventSelectedTypeId: createSelector(
		(state) => state,
		(state) => state.eventForm.selected.eventTypeId,
		(state, selectedId) => {
			let eventTypeById;
			const selectedEventTypeId = selectedId;

			if (!selectedEventTypeId) return null;

			eventTypeById = eventFormSelectors.getEventTypeById(state, selectedEventTypeId);

			if (!eventTypeById || eventTypeById?.Title === "") return null;

			return {
				id: selectedEventTypeId.toString(),
				text: eventTypeById?.Title
			};
		}
	),
	getEventSelectedFilterSearchTypeId: createSelector(
		(state) => state,
		(state) => state.eventForm.filter.eventFilterSearchType,
		(state, selectedSearchId) => {
			let eventTypeById;
			const selectedEventSearchTypeId = selectedSearchId;

			if (!selectedEventSearchTypeId) return null;

			eventTypeById = eventFormSelectors.getEventTypeById(state, selectedEventSearchTypeId);

			if (!eventTypeById || eventTypeById?.Title === "") return null;

			return {
				id: selectedEventSearchTypeId.toString(),
				text: eventTypeById?.Title
			};
		}
	),
	getEventTypeById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): IEventTypeItem => {
			return eventTypesValues.find((item) => item.Id === itemId);
		}
	),
	getEventSelectedFilterSearchStatusId: createSelector(
		(state) => state,
		(state) => state.eventForm.filter.eventFilterSearchStatusId,
		(state, selectedSearchStatusId) => {
			let eventStatusById;
			const selectedEventSearchStatusId = selectedSearchStatusId;

			if (!selectedEventSearchStatusId) return null;

			eventStatusById = eventFormSelectors.getEventStatusById(state, selectedEventSearchStatusId);

			if (!eventStatusById || eventStatusById?.title === "") return null;

			return {
				id: selectedEventSearchStatusId.toString(),
				text: eventStatusById?.title
			};
		}
	),
	getEventStatusById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): ITreeItem => {
			return eventStatusValues.find((item) => item.id === itemId);
		}
	),
	getEventsList: (state): IEventFormDataResponse[] => state.eventForm.events.list,
	getEventStartDate: (state): Date => state.eventForm.selected.startDate,
	getEventEndDate: (state): Date => state.eventForm.selected.endDate,
	getEventPublishDate: (state): Date => state.eventForm.selected.publishDate,
	getEventAllowedUsersList: (state): IEventAllowListResponse[] => state.eventForm.event.allowedUsers,
	getEventAllowedUsersPanelOpen: (state): boolean => state.eventForm.ui.allowedUsersPanelOpen,
	getEventProcessFilePanelOpen: (state): boolean => state.eventForm.ui.processFilePanelOpen,
	getEventAllowedUsersChanged: (state): boolean => state.eventForm.ui.allowedUsersChanged,
	getIsOpenDeleteEventModal: (state): boolean => state.eventForm.ui.isOpenDeleteEventModal,
	getIsOpenPublishEventModal: (state): boolean => state.eventForm.ui.isOpenPublishEventModal,
	getIsEventListLoaded: (state): boolean => state.eventForm.ui.isEventListLoaded,
	getIsEventListLoading: (state): boolean => state.eventForm.ui.isEventListLoading,
	getEventSelectedId: (state): string => state.eventForm.checkin.eventSelectedId,
	getEventFilterSearchText: (state): string => state.eventForm.filter.eventFilterSearchText,
	getEventFilterSearchType: (state): string => state.eventForm.filter.eventFilterSearchType,
	getEventFilterSearchTypeOption: (state): string => state.eventForm.filter.eventFilterSearchTypeOption,
	getEventFilterSearchStatus: (state): string => state.eventForm.filter.eventFilterSearchStatus
};
