import React, { useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";

import headerLogo from "../../StrikeLogo.svg";
import { MenuBuilder } from "./shared/menuBuilder";
import { MenuUserSection } from "./shared/menuUserSection";
import { ServiceHub } from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { useEffect } from "react";
import { ScreenWidthSize, useScreenSize } from "../../hooks";
import { setIsHighZoomLevel } from "../../redux/reducers/appManagement";
import { useFilterSlice } from "../../hooks/filter/useFilterSlice";

/**
 * Application Header Menu.
 *
 * @returns React.ReactElement
 */
const HeaderMenu = (): React.ReactElement => {
	const dispatch = useDispatch();
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const isMobileDevice = useScreenSize(ScreenWidthSize.Mobile);
	const bannerVisible = useSelector(appManagementSelectors.getBannerVisible);
	const filterSvc = useRef(ServiceHub.filterAPI.start());
	const params = useParams();
	const filterData = useFilterSlice();

	useEffect(() => {
		if (!isMobileDevice) {
			const checkZoomLevel = () => {
				dispatch(setIsHighZoomLevel(ServiceHub.appManagementAPI.isHighZoomLevel()));
			};
	
			checkZoomLevel();
	
			window.addEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			window.addEventListener("resize", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			return () => {
				window.removeEventListener("resize", ServiceHub.appManagementAPI.handleZoomLevelScroll);
				window.removeEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			};
		}
	}, [isMobileDevice]);

	/**
	 * Utility method to process filter type and get nodes
	 * @param filterType 
	 */
	const resetHomeNavigationNodes = () => {
		let resetQueryModel = ServiceHub.nodeAPI.getQueryNodeObject(
			Number(params.id),
			1,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined
		);
		filterData.getNodesList(resetQueryModel);
	}

	const handleHomeNavigation = () => {
		ServiceHub.appManagementAPI.resetDashboardFilters();
		filterSvc.current.setDashboardNavigation(undefined);
		filterSvc.current.resetUserCustomDashboard();
		resetHomeNavigationNodes();
		ServiceHub.appMenuAPI.start().resetAllMenu();
	};

	return (
		<section
			className={`header-container ${isHighZoomLevel ? "high-zoom-level" : ""} ${
				!bannerVisible ? "banner-not-visible" : ""
			}`}
		>
			<Navbar className="container" expand="lg" variant="dark" collapseOnSelect>
				<Navbar.Brand>
					<Link to={ApplicationRoutePaths.root()} onClick={handleHomeNavigation}>
						<img className="nav-link-logo" src={headerLogo} alt="Strike Logo" />
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-toggle" />
				<Navbar.Collapse id="navbar-toggle">
					<Nav className="me-auto ">
						<MenuBuilder />
					</Nav>
				</Navbar.Collapse>
				<MenuUserSection />
			</Navbar>
		</section>
	);
};

export default HeaderMenu;
