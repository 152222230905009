import { useSelector } from "react-redux";
import { eventFormSelectors } from "../../redux/selectors/eventForm";
import { useMemo } from "react";
import { IEventAllowListRequest, IEventFormData, IEventFormDataResponse } from "../../contracts/event/eventForm";
import { IHtmlVirtualizationHook, useHtmlVirtualization } from "../html/useHtmlVirtualization";
import { useState } from "react";
import { EventTypes } from "../../contracts/models/strikeEnums";
import { ITreeItem } from "@coherence-design-system/tree-view";

/**
 * Contract for the Event Form hook.
 */
interface IEventFormHook {
	isNewEvent: boolean;
	validation: IEventFormValidation;
	eventsList: IEventFormDataResponse[];
	allowedUserSelection: IEventAllowListRequest[];
	canSubmit: boolean;
	requireCapacity: boolean;
	selectedEventType: any;
	eventTypes: ITreeItem[];
	eventStatus: ITreeItem[];
	eventAllList: ITreeItem[];
	eventSelectList: ITreeItem[];
	eventFormData: IEventFormData;
	bodySourceMode: boolean;
	getEventStartDate: Date;
	getEventEndDate: Date;
	getEventPublishDate: Date;
	virtualHTML: IHtmlVirtualizationHook;
	hasError: boolean;
	setHasError: (error: boolean) => void | Promise<void>;
	isAllowUsersPanelOpen: boolean;
	isProcessFilePanelOpen: boolean;
	isAllowUsersChanged: boolean;
	isOpenDeleteEventModal: boolean;
	isOpenPublishEventModal: boolean;
	isEventListLoaded: boolean;
	isEventListLoading: boolean;
	eventSelectedItem: any;
	selectedEventTypeId: any;
	selectedEventSearchTypeId: any;
	selectedEventSearchStatusId: any;
	eventSelectedId: string;
	eventFilterSearchText: string;
	eventFilterSearchType: string;
	eventFilterSearchStatus: string;
	eventFilterSearchTypeOption: string;
}

/**
 * Event Form Validation properties shape
 */
export interface IEventFormValidation {
	[key: string]: boolean;
	id?: boolean;
	title: boolean;
	type: boolean;
	dateStart: boolean;
	dateEnd: boolean;
	presentedBy?: boolean;
	venue: boolean;
	pointOfContact?: boolean;
	InPersonCapacity?: boolean;
	food?: boolean;
	description: boolean;
}

/**
 * Hook to handle and processing Event Form data to
 * use in Manage Spaces.
 * @returns IEventFormHook
 */
export const useEventForm = (): IEventFormHook => {
	const eventFormData = useSelector(eventFormSelectors.getData);
	const bodySourceMode = useSelector(eventFormSelectors.getBodySourceMode);
	const selectedEventType = useSelector(eventFormSelectors.getSelectedEventType);
	const selectedEventTypeId = useSelector(eventFormSelectors.getEventSelectedTypeId);
	const selectedEventSearchTypeId = useSelector(eventFormSelectors.getEventSelectedFilterSearchTypeId);
	const selectedEventSearchStatusId = useSelector(eventFormSelectors.getEventSelectedFilterSearchStatusId);
	const eventFilterSearchTypeOption = useSelector(eventFormSelectors.getEventFilterSearchTypeOption);
	const eventSelectedItem = useSelector(eventFormSelectors.getEventSelectedItem);
	const eventSelectedId = useSelector(eventFormSelectors.getEventSelectedId);
	const eventsList = useSelector(eventFormSelectors.getEventsList);
	const eventTypes = useSelector(eventFormSelectors.getEventTypes);
	const eventStatus = useSelector(eventFormSelectors.getStatusTypes);
	const eventSelectList = useSelector(eventFormSelectors.getEventSelectList);
	const eventAllList = useSelector(eventFormSelectors.getAllEventList);
	const getEventStartDate = useSelector(eventFormSelectors.getEventStartDate);
	const getEventEndDate = useSelector(eventFormSelectors.getEventEndDate);
	const getEventPublishDate = useSelector(eventFormSelectors.getEventPublishDate);
	const allowedUserSelection = useSelector(eventFormSelectors.getEventAllowedUsersList);
	const isAllowUsersPanelOpen = useSelector(eventFormSelectors.getEventAllowedUsersPanelOpen);
	const isProcessFilePanelOpen = useSelector(eventFormSelectors.getEventProcessFilePanelOpen);
	const isAllowUsersChanged = useSelector(eventFormSelectors.getEventAllowedUsersChanged);
	const isOpenDeleteEventModal = useSelector(eventFormSelectors.getIsOpenDeleteEventModal);
	const isOpenPublishEventModal = useSelector(eventFormSelectors.getIsOpenPublishEventModal);
	const isEventListLoaded = useSelector(eventFormSelectors.getIsEventListLoaded);
	const isEventListLoading = useSelector(eventFormSelectors.getIsEventListLoading);
	const eventFilterSearchText = useSelector(eventFormSelectors.getEventFilterSearchText);
	const eventFilterSearchType = useSelector(eventFormSelectors.getEventFilterSearchType);
	const eventFilterSearchStatus = useSelector(eventFormSelectors.getEventFilterSearchStatus);
	const [hasError, setHasError] = useState(false);

	const validation = useMemo<IEventFormValidation>(() => {
		return {
			title: eventFormData.title !== "",
			type: eventFormData.title !== "",
			dateStart: eventFormData.dateStart !== null,
			dateEnd: eventFormData.dateEnd !== null,
			venue: eventFormData.venue !== "",
			description: eventFormData.description !== ""
		};
	}, [eventFormData]);

	/**
	 * Differentiates between New and Existing Event
	 */
	const isNewEvent = useMemo(() => {
		return !eventFormData?.id;
	}, [eventFormData]);

	/**
	 * Check Event Type to show/hide extra capacity number field
	 */
	const requireCapacity = useMemo(() => {
		return eventFormData.type === EventTypes.InPerson || eventFormData.type === EventTypes.Mixed;
	}, [eventFormData]);

	const virtualHTML = useHtmlVirtualization(eventFormData.description);

	const canSubmit = useMemo(
		() =>
			validation.title &&
			validation.type &&
			validation.dateStart &&
			validation.dateEnd &&
			validation.venue &&
			validation.description,
		[validation]
	);

	return {
		isNewEvent,
		validation,
		canSubmit,
		eventTypes,
		eventStatus,
		eventSelectList,
		eventAllList,
		requireCapacity,
		selectedEventType,
		eventSelectedItem,
		selectedEventTypeId,
		selectedEventSearchTypeId,
		eventFormData,
		bodySourceMode,
		virtualHTML,
		hasError,
		setHasError,
		eventsList,
		getEventStartDate,
		getEventEndDate,
		getEventPublishDate,
		allowedUserSelection,
		isAllowUsersPanelOpen,
		isProcessFilePanelOpen,
		isAllowUsersChanged,
		isOpenDeleteEventModal,
		isOpenPublishEventModal,
		isEventListLoaded,
		isEventListLoading,
		eventSelectedId,
		eventFilterSearchText,
		eventFilterSearchType,
		eventFilterSearchTypeOption,
		eventFilterSearchStatus,
		selectedEventSearchStatusId
	} as IEventFormHook;
};
