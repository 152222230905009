/* Structural parts' imports */
import { LazyServiceStarter } from "./LazyServiceStarter";
import { IServiceHubFixture } from "../../contracts/services/IServiceHubFixture";

/* Importing every referential service */
import { AppMessageService, MessageServiceInstance } from "../message/AppMessage";
import { AppFileAPI } from "../app/AppFileAPI";
import { AppManagementAPI, AppManagementAPIInstance } from "../AppManagementAPI";
import { AppMenuAPI } from "../app/AppMenuAPI";
import { AppTreeViewAPI } from "../app/AppTreeViewAPI";
import { ArticleDataAPI } from "../article/ArticleDataAPI";
import { ArticleFormService } from "../article/ArticleFormService";
import { GraphAPIService } from "../GraphAPI";
import { NodeAPIService, NodeAPIServiceInstance } from "../NodeAPI";
import { UserProfileAPIService } from "../userProfile/UserProfileAPI";
import { SpacesAPIService } from "../space/SpacesAPI";
import { SpaceAdminAPI } from "../space/SpaceAdminAPI";
import { NodeAdminAPI } from "../node/NodeAdminAPI";
import { SuperAdminAPI } from "../node/SuperAdminAPI";
import { FilterAPIService } from "../filter/FilterAPI";
import { FollowAPI } from "../follow/FollowAPI";
import { TopicsAPIService } from "../topic/TopicsAPI";
import { EventsAPIService } from "../events/eventsAPI";
import { CommentAPI } from "../comment/CommentAPI";
import { ProcessingQueueAPI } from "../utils/ProcessingQueueAPI";
import { SpaceFormService } from "../space/SpaceFormService";
import { EventFormService } from "../events/EventFormService";
import { EventManageAPI } from "../events/EventAPIService";
import { ContrastDetection } from "../utils/ContrastDetection";
import { GlobalBannerFormService } from "../banner/GlobalBannerFormService";
import { GlobalBannerAdminAPI } from "../banner/GlobalBannerAdminAPIService";

/**
 * Strike Application's Service Hub.
 * Concentrates all services used through the system in an effort
 * to maintain them across an individual reference/injection point.
 */
class ServiceHubCentral implements IServiceHubFixture {
	// Singleton services
	nodeAPI: NodeAPIService;
	message: AppMessageService;
	appManagementAPI: AppManagementAPI;
	processingQueueAPI: ProcessingQueueAPI;

	// Lazy services
	appFileAPI: LazyServiceStarter<AppFileAPI>;
	appMenuAPI: LazyServiceStarter<AppMenuAPI>;
	appTreeViewAPI: LazyServiceStarter<AppTreeViewAPI>;
	articleForm: LazyServiceStarter<ArticleFormService>;
	articleDataAPI: LazyServiceStarter<ArticleDataAPI>;
	spaceAdminAPI: LazyServiceStarter<SpaceAdminAPI>;
	commentAPI: LazyServiceStarter<CommentAPI>;
	graphAPI: LazyServiceStarter<GraphAPIService>;
	userProfileAPI: LazyServiceStarter<UserProfileAPIService>;
	spacesAPI: LazyServiceStarter<SpacesAPIService>;
	nodeAdminAPI: LazyServiceStarter<NodeAdminAPI>;
	superAdminAPI: LazyServiceStarter<SuperAdminAPI>;
	filterAPI: LazyServiceStarter<FilterAPIService>;
	followAPI: LazyServiceStarter<FollowAPI>;
	topicsAPI: LazyServiceStarter<TopicsAPIService>;
	eventsAPI: LazyServiceStarter<EventsAPIService>;
	spaceForm: LazyServiceStarter<SpaceFormService>;
	globalBannerForm: LazyServiceStarter<GlobalBannerFormService>;
	globalBannerAdminAPI: LazyServiceStarter<GlobalBannerAdminAPI>;
	eventForm: LazyServiceStarter<EventFormService>;
	eventManageAPI: LazyServiceStarter<EventManageAPI>;
	contrastDetection: LazyServiceStarter<ContrastDetection>;

	constructor() {
		// Begin: Singleton services, then those which
		// must be always available.
		this.nodeAPI = NodeAPIServiceInstance;
		this.message = MessageServiceInstance;
		this.appManagementAPI = AppManagementAPIInstance;
		this.processingQueueAPI = new ProcessingQueueAPI();

		// Begin: Lazy services,
		// which must be started by calling .start() prior to their own function calling.
		this.appFileAPI = new LazyServiceStarter(AppFileAPI);
		this.appMenuAPI = new LazyServiceStarter(AppMenuAPI);
		this.appTreeViewAPI = new LazyServiceStarter(AppTreeViewAPI);
		this.articleForm = new LazyServiceStarter(ArticleFormService);
		this.articleDataAPI = new LazyServiceStarter(ArticleDataAPI);
		this.spaceAdminAPI = new LazyServiceStarter(SpaceAdminAPI);
		this.commentAPI = new LazyServiceStarter(CommentAPI);
		this.graphAPI = new LazyServiceStarter(GraphAPIService);
		this.userProfileAPI = new LazyServiceStarter(UserProfileAPIService);
		this.spacesAPI = new LazyServiceStarter(SpacesAPIService);
		this.nodeAdminAPI = new LazyServiceStarter(NodeAdminAPI);
		this.filterAPI = new LazyServiceStarter(FilterAPIService);
		this.followAPI = new LazyServiceStarter(FollowAPI);
		this.topicsAPI = new LazyServiceStarter(TopicsAPIService);
		this.eventsAPI = new LazyServiceStarter(EventsAPIService);
		this.spaceForm = new LazyServiceStarter(SpaceFormService);
		this.eventForm = new LazyServiceStarter(EventFormService);
		this.superAdminAPI = new LazyServiceStarter(SuperAdminAPI);
		this.eventManageAPI = new LazyServiceStarter(EventManageAPI);
		this.contrastDetection = new LazyServiceStarter(ContrastDetection);
		this.globalBannerForm = new LazyServiceStarter(GlobalBannerFormService);
		this.globalBannerAdminAPI = new LazyServiceStarter(GlobalBannerAdminAPI);
	}
}

/**
 * Strike Application's Service Hub instance.
 * Access services by navigating the props.
 * Lazy services should be activated first, by calling .start().
 */
export const ServiceHub: ServiceHubCentral = new ServiceHubCentral();
