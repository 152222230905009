import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";
import { useGlobalBannerForm } from "../../../hooks/banner/useBannerForm";
import { FormFieldsGlobalBanner } from "./global.banner.form.fields";

/**
 * Re-usable Space creation form
 *
 * @returns React.FC
 */
export const GlobalBannerForm: React.FC = () => {
	const dispatch = useDispatch();
	const { globalBannerFormData, isNewGlobalBanner } = useGlobalBannerForm();
	const globalBannerDataAPI = useRef(ServiceHub.globalBannerAdminAPI.start());
	const globalBannerFormService = useRef(ServiceHub.globalBannerForm.start());

	/**
	 * Executes the necessary commands for Creating a Space
	 */
	const createGlobalBanner = () => {
		globalBannerDataAPI.current.createGlobalBanner(globalBannerFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
		
			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}
		
			globalBannerFormService.current.resetData();
			globalBannerFormService.current.setBannerImageFile('');
			globalBannerDataAPI.current.getDashboardBannerImages().then((result) => {
				if (!Array.isArray(result)) return;
				globalBannerFormService.current.setBannersList(result)
			})
			setTimeout(() => {
				globalBannerFormService.current.setBannerPanelOpen(false);
			}, 3000);
		});
	};

	/**
	 * Executes the necessary commands for Edit a Space,
	 */
	const editGlobalBanner = () => {
		globalBannerDataAPI.current.createGlobalBanner(globalBannerFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
		
			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}
		
			globalBannerFormService.current.resetData();
			globalBannerFormService.current.setBannerImageFile('');
			globalBannerFormService.current.resetBannerId();
			globalBannerDataAPI.current.getDashboardBannerImages().then((result) => {
				if (!Array.isArray(result)) return;
				globalBannerFormService.current.setBannersList(result)
			})
			setTimeout(() => {
				globalBannerFormService.current.setBannerPanelOpen(false);
			}, 3000);
		});

	};

	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();

		if (isNewGlobalBanner) {
			createGlobalBanner();
		} else {
			editGlobalBanner();
		}

		return;
	}

	return !globalBannerFormData ? null : <FormFieldsGlobalBanner onSubmit={onSubmit} />;
};