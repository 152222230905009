import React from 'react';

const TopicsExpertsPointsBar = ({ points }) => {
    const validPoints = isNaN(points) ? 0 : points;
    const widthPercentage = validPoints > 100 ? 100 : validPoints;

    const barPercentage = {
        width: `${widthPercentage}%`,
    };

    return (
        <div className="topic-expert-points-bar">
            <div style={barPercentage} className="topic-expert-points-bar-points">
                <span className="topic-expert-points-bar-points-value" aria-label={`${points} Points`} tabIndex={0}>{points} Points </span>
            </div>
        </div>
    );
};

export default TopicsExpertsPointsBar;