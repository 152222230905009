/**
 * Redux config to manage space Form data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { IGlobalBannerFormData, IGlobalBannerItemsResponse, ISpaceFormData } from "../../contracts";
import { globalBannerFormInitialState } from "../../models/slices/banner/globalBannerFormInitialState";

/**
 * Article Form state management layer, also named after a Redux Slice.
 */
export const globalBannerFormSlice = createSlice({
	name: AppSlices.globalBannerForm,
	initialState: globalBannerFormInitialState,
	reducers: {
		setData: (state, action: PayloadAction<IGlobalBannerFormData>) => {
			state.data = action.payload;
		},
		resetData: (state) => {
			state.data = globalBannerFormInitialState.data;
		},
        setBannerImageFile: (state, action: PayloadAction<string>) => {
			state.bannerImageFile = action.payload;
		},
		setBannersList: (state, action: PayloadAction<IGlobalBannerItemsResponse[]>) => {
			state.bannersList = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setBannerPanelOpen: (state, action: PayloadAction<boolean>) => {
			state.bannerPanelOpen = action.payload;
		},
		setBannerId: (state, action: PayloadAction<string>) => {
			state.bannerId = action.payload;
		},
		resetBannerId: (state) => {
			state.bannerId = globalBannerFormInitialState.bannerId;
		},
	}
});

export const globalBannerFormActions = globalBannerFormSlice.actions;

export default globalBannerFormSlice.reducer;