import { createSelector } from "@reduxjs/toolkit";
import { ITopicsExpertsResponse } from "../../contracts/topic/ITopicsExpertResponse";

/**
 * Global Banner Form data access selectors.
 */
export const topicsExpertsSelectors = {
    getTopicsExperts: (state): ITopicsExpertsResponse[] => {
        return state.topicsExperts.data;
    },
    getFirstThreeExperts: createSelector(
		(state) => state,
		(state): ITopicsExpertsResponse[] => {
			const firstThreeExperts = state.topicsExperts.data.slice(0, 3)
			return firstThreeExperts;
		}
	),
    getAdditionalExperts: createSelector(
		(state) => state,
		(state): ITopicsExpertsResponse[] => {
			const additionalExperts = state.topicsExperts.data.slice(3)
			return additionalExperts;
		}
	),
};