import React, { useEffect, useState } from "react";
import { TopicsExpertsBox } from "./topics.experts.box";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetTopicsExperts, setTopicsExperts } from "../../redux/reducers/topicsExperts";
import { useTopicsExpertsData } from "../../hooks/topic/useTopicsExperts";

interface Props {

}

export const TopicsExperts: React.FunctionComponent<Props> = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const {topThreeExperts} = useTopicsExpertsData();
    const [isExpertsDataLoaded, setIsExpertsDataLoaded] = useState(false)
    const [topicsExpertsFullData, setTopicsExpertsFullData] = useState([])

    const getTopicsExpertsInfo = async () => {
        let res = await NodeAPIServiceInstance.getTopicsExperts(params.id);
        if (res) {
            dispatch(setTopicsExperts(res))
            setIsExpertsDataLoaded(true)
            setTopicsExpertsFullData(res)
        }
    };

    useEffect(() => {
        dispatch(resetTopicsExperts())
        getTopicsExpertsInfo()
    }, [params.id]);

    return (
        isExpertsDataLoaded && topicsExpertsFullData.length > 0 ?
        <div className="topics-experts" tabIndex={-1} aria-label="Topics Experts Box">
            <div className="topics-experts-headline-wrapper" tabIndex={-1}>
                <div className="topics-experts-headline" tabIndex={-1}>
                    <h4 tabIndex={-1}>Topic Experts</h4>
                </div>
            </div>
            <div className="ui divider" />
            <div>
            <TopicsExpertsBox />
            </div>
        </div>
        : null
    );
};