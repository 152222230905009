import { IArticleFormData } from "../../contracts/article/articleForm";
import { CoverImageType } from "../../enums";
import BaseHttpAPI from "./BaseHttpAPI";
import { ServiceHub } from "./ServiceHub";

/**
 * Shared abstraction which implements shared resources,
 * for dealing with Node data.
 */
export abstract class NodePreprocessingAPI extends BaseHttpAPI {
	private allowedExtensionsMap = {
		png: "png",
		jpg: "jpg",
		svg: "svg",
		gif: "gif"
	};

	constructor(serviceUrlPrefix: string) {
		super(serviceUrlPrefix);
	}

	/**
	 * Detects all images within an Article's body data and
	 * uploads all base-64 images to replace with a server-based
	 * file URL.
	 *
	 * @param textData The text data to be processed
	 */
	async detectBodyImages(textData: string): Promise<string> {
		const appFileAPI = ServiceHub.appFileAPI.start();
		// To capture all images within the body of the article,
		// and pre-upload them to the storage for replacing with the location
		// from served blobstorage, there is the use of a RegExp object.
		const attachmentsUploaded = [];
		let dataCopy = `${textData}`;

		// Regex to detect images in the body of the text
		// - Source 1
		// End: /src="(data:image\/[^;]+;base64,[^"]+)"/igm
		// /src="(data:image\/[^;]+;base64,[^"]+)"/gim
		const imageCaptureRegex = new RegExp(/src="(data:(image|application)\/[^;]+;base64,[^"]+)"/gim);

		if (dataCopy !== "" && imageCaptureRegex.test(dataCopy)) {
			const imageSafeTextTransforms: Promise<void>[] = [];
			const regexMatch = dataCopy.match(imageCaptureRegex);
			const firstMatchOrNull = !regexMatch ? null : regexMatch[0];

			if (firstMatchOrNull !== null) {
				regexMatch.map((found) => {
					const splitParts = found.split("base64,");

					// Determines the file extension based on a map of those allowed ones
					let imageExtension = "ext";
					for (let ext in this.allowedExtensionsMap) {
						if (splitParts[0].includes(ext)) {
							imageExtension = this.allowedExtensionsMap[ext];
							break;
						}
					}

					let cleanBase64 = splitParts[1];
					cleanBase64 = cleanBase64.endsWith('"')
						? cleanBase64.substring(0, cleanBase64.length - 1)
						: cleanBase64;

					if (!attachmentsUploaded.includes(cleanBase64)) {
						imageSafeTextTransforms.push(
							appFileAPI.attachmentUpload(
								cleanBase64,
								`image-upload.${imageExtension}`,
								(uploadedFilePath) => {
									attachmentsUploaded.push(cleanBase64);
									dataCopy = dataCopy.replaceAll(found, `src="${uploadedFilePath}"`);
								}
							)
						);
					}

					return cleanBase64;
				});

				await Promise.all(imageSafeTextTransforms);
			}
		}

		return dataCopy;
	}

	/**
	 * Will upload a cover image, if necessary,
	 * since it can contain a base-64 at the coverImageFile property.
	 *
	 * @param data The Node data to process
	 */
	async processCoverImage(data: IProcessCoverImage, onComplete?: (uploadedResult: string) => void): Promise<void> {
		const appFileAPI = ServiceHub.appFileAPI.start();

		if (data.coverImageFile !== null && data.coverImageType === CoverImageType.Upload) {
			const coverImageFileData = data.coverImageFile.includes("base64,")
				? data.coverImageFile.split("base64,")[1]
				: data.coverImageFile;

			await appFileAPI.coverImageUpload(
				coverImageFileData,
				data.coverImagePath,
				typeof onComplete === "function"
					? onComplete
					: (uploadedResult) => {
							data.coverImagePath = uploadedResult;
							data.coverImageFile = null;
					}
			);
		}
	}

	/**
	 * Will upload a banner image, if necessary,
	 * since it can contain a base-64 at the bannerImageFile property.
	 *
	 * @param data The Node data to process
	 */
	async processBannerImage(data: IProcessBannerImage, onComplete?: (uploadedResult: string) => void): Promise<void> {
		const appFileAPI = ServiceHub.appFileAPI.start();

		if (data.bannerImageFile !== null && data.bannerImageType === CoverImageType.Upload) {
			const bannerImageFileData = data.bannerImageFile.includes("base64,")
				? data.bannerImageFile.split("base64,")[1]
				: data.bannerImageFile;

			await appFileAPI.bannerImageUpload(
				bannerImageFileData,
				data.bannerImagePath,
				typeof onComplete === "function"
					? onComplete
					: (uploadedResult) => {
							data.bannerImagePath = uploadedResult;
							data.bannerImageFile = null;
					}
			);
		}
	}
}


export interface IProcessCoverImage {
	coverImageFile: string;
	coverImageType: CoverImageType;
	coverImagePath: string;
	[key: string]: any;
}

export interface IProcessBannerImage {
	bannerImageFile: string;
	bannerImageType: CoverImageType;
	bannerImagePath: string;
	[key: string]: any;
}

