import store from "../../redux/store";
import { eventFormActions } from "../../redux/reducers/eventForm";
import { IEventAllowListResponse, IEventFormData, IEventFormDataResponse } from "../../contracts/event/eventForm";

/**
 * Event creation/edition microserviceto interacte with Redux state layer.
 */
export class EventFormService {
	/**
	 * Set form event data to use on create and edit event
	 * @param data
	 */
	setData(data: IEventFormData): void {
		store.dispatch(eventFormActions.setData(data));
	}

	/**
	 * resets event form data once its created or edited
	 */
	resetData(): void {
		store.dispatch(eventFormActions.resetData());
		store.dispatch(eventFormActions.setSelectedEventTypeId(null));
		store.dispatch(eventFormActions.setSelectedStartDate(null));
		store.dispatch(eventFormActions.setSelectedEndDate(null));
		store.dispatch(eventFormActions.setSelectedPublishDate(null));
	}

	setBodySourceMode(sourceMode: boolean): void {
		store.dispatch(eventFormActions.setBodySourceMode(sourceMode));
	}

	setSelectedEventTypeId(eventType: null | string | number): void {
		store.dispatch(eventFormActions.setSelectedEventTypeId(eventType));
	}

	setSelectedEventIdToCheckin(eventSelected: null | string | number): void {
		store.dispatch(eventFormActions.setSelectedEventIdToCheckin(eventSelected));
	}

	setEventsList(eventList: IEventFormDataResponse[]): void {
		store.dispatch(eventFormActions.setEventsList(eventList));
	}

	setSelectedStartDate(date: Date): void {
		store.dispatch(eventFormActions.setSelectedStartDate(date));
	}

	setSelectedEndDate(date: Date): void {
		store.dispatch(eventFormActions.setSelectedEndDate(date));
	}

	setSelectedPublishDate(date: Date): void {
		store.dispatch(eventFormActions.setSelectedPublishDate(date));
	}

	setEventAllowedUserList(allowedUsersList: IEventAllowListResponse[]): void {
		store.dispatch(eventFormActions.setEventAllowedUserList(allowedUsersList));
	}

	resetEventAllowedUserList(): void {
		store.dispatch(eventFormActions.resetEventAllowedUserList());
	}

	removeUserFromAllowedList(emailId: string): void {
		store.dispatch(eventFormActions.removeUserFromAllowedList(emailId));
	}

	setIsAllowUsersPanelOpen(value: boolean): void {
		store.dispatch(eventFormActions.setIsAllowUsersPanelOpen(value));
	}

	setIsProcessFilePanelOpen(value: boolean): void {
		store.dispatch(eventFormActions.setIsProcessFilePanelOpen(value));
	}

	setIsAllowUsersChanged(value: boolean): void {
		store.dispatch(eventFormActions.setIsAllowUsersChanged(value));
	}

	setIsOpenDeleteEventModal(value: boolean): void {
		store.dispatch(eventFormActions.setIsOpenDeleteEventModal(value));
	}

	setIsOpenPublishEventModal(value: boolean): void {
		store.dispatch(eventFormActions.setIsOpenPublishEventModal(value));
	}

	setIsEventListLoaded(value: boolean): void {
		store.dispatch(eventFormActions.setIsEventListLoaded(value));
	}

	setIsEventListLoading(value: boolean): void {
		store.dispatch(eventFormActions.setIsEventListLoading(value));
	}

	setEventFilterSearchText(searchNameValue: string): void {
		store.dispatch(eventFormActions.setEventFilterSearchText(searchNameValue));
	}

	setEventFilterSearchType(searchTypeIdValue: string): void {
		store.dispatch(eventFormActions.setEventFilterSearchType(searchTypeIdValue));
	}

	setEventFilterSearchTypeOption(searchTypeTextValue: string): void {
		store.dispatch(eventFormActions.setEventFilterSearchTypeOption(searchTypeTextValue));
	}

	setEventFilterSearchStatus(searchStatusValue: string): void {
		store.dispatch(eventFormActions.setEventFilterSearchStatus(searchStatusValue));
	}

	setEventFilterSearchStatusId(searchStatusIdValue: string): void {
		store.dispatch(eventFormActions.setEventFilterSearchStatusId(searchStatusIdValue));
	}

	setSelectedEventStatusId(eventStatus: null | string): void {
		store.dispatch(eventFormActions.setEventFilterSearchStatusId(eventStatus));
	}
}
