import store from "../../redux/store";
import { IGlobalBannerFormData, IGlobalBannerItemsResponse} from "../../contracts";
import { IGroup } from "@fluentui/react";
import { spaceFormActions } from "../../redux/reducers/spaceForm";
import { globalBannerFormActions } from "../../redux/reducers/globalBannerForm";

/**
 * Space Form microservice, which allows interacting with the Redux state layer.
 */
export class GlobalBannerFormService {
	/**
	 * Set form space data to use on create and edit space
	 * @param data
	 */
	setData(data: IGlobalBannerFormData): void {
		store.dispatch(globalBannerFormActions.setData(data));
	}

	/**
	 * resets space form data once its created or edited
	 */
	resetData(): void {
		store.dispatch(globalBannerFormActions.resetData());
	}

	/**
	 * Save in state data image used to show space coverImage at the form
	 * @param dataImage
	 */
	setBannerImageFile(dataImage: string) {
		store.dispatch(globalBannerFormActions.setBannerImageFile(dataImage));
	}

	/**
	 * Set Banner Dashboard items to display in Table
	 */
	setBannersList(bannersList: IGlobalBannerItemsResponse[]): void {
		store.dispatch(globalBannerFormActions.setBannersList(bannersList));
	}

	/**
	 * Set loadimg flag to manage content loading process
	 */
	setBannersLoading(value: boolean) {
		store.dispatch(globalBannerFormActions.setLoading(value));
	}

	/**
	 * Set loadimg flag to manage content loading process
 	 */
	setBannersLoaded(value: boolean) {
		store.dispatch(globalBannerFormActions.setLoaded(value));
	}

	/**
	 * Set banner panel open flag
 	 */
	setBannerPanelOpen(value: boolean) {
		store.dispatch(globalBannerFormActions.setBannerPanelOpen(value));
	}

	/**
	 * Set banner Id to switch to edit mode
 	 */
	setBannerId(value: string) {
		store.dispatch(globalBannerFormActions.setBannerId(value));
	}

	/**
	 * Reset banner Id to switch to edit mode
	 */
	resetBannerId() {
		store.dispatch(globalBannerFormActions.resetBannerId());
	}
}
