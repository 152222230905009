import { IGlobalBannerFormData, IGlobalBannerItemsResponse, ISpaceFormData, ISpaceFormResponse } from "../../contracts";
import { IProcessBannerImage, IProcessCoverImage, NodePreprocessingAPI } from "../base/NodePreprocessingAPI";

/**
 * Space Admin tooling services class.
 */
export class GlobalBannerAdminAPI extends NodePreprocessingAPI {
    constructor() {
        super("admin");
    }

    	/**
	 * Create new global Banner endpoint
	 * @param globalBannerBody
	 * @returns
	 */
	async createGlobalBanner(globalBannerBody: IGlobalBannerFormData): Promise<boolean> {
		const globalBannerBodyCopy: any = { ...globalBannerBody, bannerImagePath: globalBannerBody.bannerImageName };
		await this.processBannerImage(globalBannerBodyCopy as IProcessBannerImage, (uploadedResult) => {
			globalBannerBodyCopy.bannerImageName = uploadedResult;
		});
		return await this.post<IGlobalBannerFormData, { HasError: boolean }>(`setDashboardBannerImage`, globalBannerBodyCopy)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

    /**
     * Get all Global Banners
     * @returns
     */
    async getDashboardBannerImages(): Promise<IGlobalBannerItemsResponse> {
        return await this.get(`getDashboardBannerImages`)
            .then((result) => result as IGlobalBannerItemsResponse)
            .catch((error) => error);
    }

	/**
	 * @param bannerId
	 */
	async deleteBannerImage(bannerId: string): Promise<boolean>{
        return await this.get(`deleteDashboardBannerImage/${bannerId}`)
            .then((result) => true)
            .catch((error) => error);
    }

	/**
	 * @param bannerId
	 */
	async getBannerImage(bannerId: string): Promise<IGlobalBannerItemsResponse>{
        return await this.get(`getDashboardBannerImage/${bannerId}`)
            .then((result) => result as IGlobalBannerItemsResponse)
            .catch((error) => error);
    }
}
