import store from "../redux/store";
import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { setLoaderText, setLoaderVisible, setBannerVisible, setIsHighZoomLevel, setStickyNodes, resetStickyNodes, setIsEventAdminUser, setIsSuperAdminUser, setIsNodeAdminUser } from "../redux/reducers/appManagement";
import { appManagementSelectors } from "../redux/selectors/appManagement";
import { ServiceHub } from "./base/ServiceHub";
import Constants from "../constants/Constants";
import { resetReportReasonValueId, setDeletedStatus, setNodeDetailAuthorEmail, setPrivateStatus, setReportReasonsOptionsData, setReportReasonValueId, setReportReasonValueOption, setSelectedReportReason, setStickSiteStatus, setStickSpaceStatus } from "../redux/reducers/nodes";
import { IStrikeNodeSticky } from "../contracts/models";

let _navigate: NavigateFunction = null;

export class AppManagementAPI {
	setNavigate(navigateFn: NavigateFunction): void {
		_navigate = navigateFn;
	}

	getNavigate(): NavigateFunction {
		return _navigate;
	}

	setLoaderVisible(visible: boolean) {
		store.dispatch(setLoaderVisible(visible));
	}

	setLoaderText(text: String) {
		store.dispatch(setLoaderText(text));
	}

	setBannerVisible(visible: boolean) {
		store.dispatch(setBannerVisible(visible));
	}

	/**
	 * Gets whether the application banner is visible at the moment.
	 *
	 * @returns boolean
	 */
	getBannerVisible(): boolean {
		return appManagementSelectors.getBannerVisible(store.getState());
	}

	/**
	 * set flag value if node its stick to the space
	 * @param value
	 */
	setNodeStickSpaceStatus(value: boolean) {
		store.dispatch(setStickSpaceStatus(value));
	}

	/**
	 * set flag value if node its stick to the entire website
	 * @param value
	 */
	setNodeStickSiteStatus(value: boolean) {
		store.dispatch(setStickSiteStatus(value));
	}

	/**
	 * set flag value if node its private or public
	 * @param value
	 */
	setNodePrivateSiteStatus(value: boolean) {
		store.dispatch(setPrivateStatus(value));
	}

	/**
	 * set flag value if node its deleted or not
	 * @param value
	 */
	setNodeDeletedStatus(value: boolean) {
		store.dispatch(setDeletedStatus(value));
	}

	/**
	 * set authorEmail value to use in node owner check globally
	 * @param value
	 */
	setNodeDetailAuthorEmail(value: string) {
		store.dispatch(setNodeDetailAuthorEmail(value));
	}

	/**
	 * Used for cleaning the filters and any other state,
	 * in order to allow for a redirect to navigate other
	 * places without restriction.
	 */
	resetDashboardFilters(): void {
		// Cleans-up the dashboard selected Space ID, Topics Filter and Dashboard Button
		ServiceHub.spacesAPI.start().setSelectedId("");
		ServiceHub.filterAPI.start().setSelectedDashBoardButton(Constants.buttonDashboardsTexts.Dashboards);
		ServiceHub.filterAPI.start().resetTopicsFilters();
	}

	handleZoomLevelScroll(): void {
		if (Math.round(window.devicePixelRatio * 100) >= Constants.zoom.level) {
			store.dispatch(setIsHighZoomLevel(true));
		} else {
			store.dispatch(setIsHighZoomLevel(false));
		}
	}

	isHighZoomLevel(): boolean {
		return Math.round(window.devicePixelRatio * 100) >= Constants.zoom.level;
	}

	/**
	 * set stickyNodees to the stat
	 * @param value
	 */
	setStickyNodes(value: IStrikeNodeSticky[]) {
		store.dispatch(setStickyNodes(value));
	}

	/**
	 * reset stickyNodes state value
	 */
	resetStickyNodes() {
		store.dispatch(resetStickyNodes());
	}

	/**
	 * Navigates the system using stored navigator helper.
	 *
	 * @param to The route destination.
	 * @param options [Optional] The options to configure the action.
	 */
	navigate(to: string, options?: NavigateOptions): void {
		_navigate(to, options);
	}

	private _resolveConfirmation: null | (() => void);

	setConfirmationCallback(callback: null | (() => void)) {
		this._resolveConfirmation = callback;
	}

	getConfirmationCallback() {
		return this._resolveConfirmation;
	}

	invokeConfirmationCallback() {
		if (typeof this._resolveConfirmation === "function") {
			this._resolveConfirmation();
			this._resolveConfirmation = null;
		}
	}

	/**
	 * Saving Report Reason selected bt dropdown
	 * @param reportReason 
	 */
	setSelectedReportReason(reportReason: null | string | number): void {
		store.dispatch(setSelectedReportReason(reportReason));
	}

	/**
	 * Saving Report Reason selected Id value bt dropdown
	 * @param reportReasonId 
	 */
	setReportReasonValueId(reportReasonId: null | string): void {
		store.dispatch(setReportReasonValueId(reportReasonId));
	}

	/**
	 * reseting selected reason option to use on clean up dropdown
	 */
	resetReportReasonValueId(): void {
		store.dispatch(resetReportReasonValueId());
	}

	/**
	 * Saving Report Reason Option text value
	 * @param reportReasonOption 
	 */
	setReportReasonValueOption(reportReasonOption: null | string): void {
		store.dispatch(setReportReasonValueOption(reportReasonOption));
	}

	/**
	 * Saving options data coming from db misc to use in dropdown
	 * @param reportReasons 
	 */
	setReportReasonsData(reportReasons: null | string[]): void {
		store.dispatch(setReportReasonsOptionsData(reportReasons));
	}

	/**
	 * set user admin access check
	 * @param value
	 */
	setIsUserEventAdminAccess(value: boolean) {
		store.dispatch(setIsEventAdminUser(value));
	}

	/**
	 * set user node admin access check
	 * @param value
	 */
	setIsUserNodeAdminAccess(value: boolean) {
		store.dispatch(setIsNodeAdminUser(value));
	}

	/**
	 * set user super admin access check
	 * @param value
	 */
	setIsUserSuperAdminAccess(value: boolean) {
		store.dispatch(setIsSuperAdminUser(value));
	}
}

/**
 * Singleton Instance of AppManagementAPI.
 */
export const AppManagementAPIInstance = new AppManagementAPI();
