import React, { useEffect } from "react";
import { EventAdminCheckInsDetails } from "./shared/event.admin.checkins";

/**
 * Component Event Check-In to handle Events Check-In actions.
 */
const EventsAdminCheckInsPage: React.FC<{}> = () => {
    useEffect(()=>{
        document.title='Event Attendance - Strike Community';
    },[])
    return <EventAdminCheckInsDetails />;

};

export default EventsAdminCheckInsPage;
