import { IGlobalBannerFormSlice } from "../../../contracts/slices/banner/IGlobalBannerFormSlice";
import { CoverImageType } from "../../../enums";
/**
 * The Initial State of an Global Banner Form slice.
 */
export const globalBannerFormInitialState: IGlobalBannerFormSlice = {
	data: {
		id: "",
		title: "",
		link: "",
        authorUpn: "",
		isCurrentBanner: false,
		bannerImageId: null,
		bannerImageType: CoverImageType.Default,
		bannerImageMimeType: null,
		bannerImageFile: null,
		bannerImageName: "",
		bannerImageSize: null
	}, 
    bannerImageFile: "",
	bannersList: null,
	loading: false,
	loaded: false,
	bannerPanelOpen: false,
	bannerId: ""
};