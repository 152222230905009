import { IEventFormSlice } from "../../../contracts/slices";
/**
 * The Initial State of an Event Form slice.
 */
export const eventFormInitialState: IEventFormSlice = {
	data: {
		id: "",
		title: "",
		type: "",
		dateStart: null,
		dateEnd: null,
		presentedBy: null,
		venue: "",
		publishSchedule: null,
		pointOfContact: "",
		description: "",
		food: "",
		isPublished: false,
		isWaitListEnabled: false,
		inPersonCapacity: null
	},
	selected: {
		eventType: null,
		eventTypeId: "",
		startDate: null,
		endDate: null,
		publishDate: null
	},
	events: {
		list: []
	},
	event: {
		allowedUsers: []
	},
	ui: {
		allowedUsersPanelOpen: false,
		processFilePanelOpen: false,
		allowedUsersChanged: false,
		isOpenDeleteEventModal: false,
		isOpenPublishEventModal: false,
		isEventListLoaded: false,
		isEventListLoading: false
	},
	checkin: {
		eventSelectedId: ""
	},
	filter: {
		eventFilterSearchText: "",
		eventFilterSearchType: "",
		eventFilterSearchTypeOption: "",
		eventFilterSearchStatus: "",
		eventFilterSearchStatusId: ""
	},
	bodySourceMode: false
};
